const djs = require('dayjs')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const advancedFormat = require('dayjs/plugin/advancedFormat')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isLeapYear = require('dayjs/plugin/isLeapYear')
const utc = require('dayjs/plugin/utc')

djs.extend(customParseFormat)
djs.extend(advancedFormat)
djs.extend(isSameOrAfter)
djs.extend(isSameOrBefore)
djs.extend(isLeapYear)
djs.extend(utc)

export const dayjs = djs
