import { z } from 'zod'
import { isIntegerString } from '../helpers/isIntegerString'

export const DocumentDocxTemplateCreateValidator = z.object({
  instanceId: z.string().refine(isIntegerString),
  thumbnailId: z.string().uuid().nullable(),
  documentId: z.string().uuid(),
  folderIds: z.string().min(1),
  label: z.string().min(1).max(255),
  notes: z.string(),
})
