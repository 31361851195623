import { IEmployeeCommThreadSummarizedSalaryReportExtra } from '../interfaces/IEmployeeCommThread'

export function isValidSummarizedSalaryReport(
  extra: IEmployeeCommThreadSummarizedSalaryReportExtra,
): boolean {
  return (
    extra.content.result === 'valid' &&
    extra.document === 'available' &&
    extra.receiver.contact !== '' &&
    !!extra.receiver.iban
  )
}
