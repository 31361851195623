import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { WebcamCapture } from './components/WebcamCapture'
import { DisplayImage } from './components/DisplayImage'
import { UploadImage } from './components/UploadImage'
import { AppContext } from 'services/connection/models/AppContext'
import { RoundIcon } from '../../../RoundIcon'
import * as uuid from 'uuid'
import { TransferState, UploadStripe } from './components/UploadStripe'
import { box } from 'services/box'
import Bugsnag from '@bugsnag/js'
import { getImageSection, IImageSection } from './helpers/getImageSection'
import { Spinner } from '../../../Spinner'
import axios from 'axios'
import { jsonParse } from '../../../../helpers/jsonParse'
import { isPlainObject } from '../../../../helpers/isPlainObject'
import { EditImage } from './components/EditImage'
import { arraybufferToBase64 } from './helpers/arraybufferToBase64'

interface Props {
  name: string
  model: Model<any>
  width: number
  height: number
  scope: 'user' | 'resident' | 'blog' | 'inventory item' | 'document template thumbnail'
  placeholder?: string
  style?
  disabled?: boolean
}

@observer
export class InputImage extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly cache = new Map<
    string,
    { id: string; src: string; width: number; height: number; section: IImageSection }
  >() // uuid => img data-uri (uploaded image) This is used to avoid immediate download after upload.
  private fileInput: HTMLInputElement | null = null
  private cancelDownload: (() => void) | null = null
  @observable private mode: 'upload' | 'webcam' | 'editing' | 'loading' | null = null
  @observable.ref private selected: {
    id: string
    src: string
    width: number
    height: number
    section: IImageSection
  } | null = null
  @observable private transfer: TransferState = {
    type: 'pending',
    progress: 0,
  }

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentWillUnmount() {
    this.cancelDownload?.()
  }

  private onSelectFile = () => this.fileInput?.click()

  @action
  private onSelect = (
    src: string,
    width: number,
    height: number,
    section?: IImageSection,
  ) => {
    let valid = true
    if (width < 200 || height < 200) {
      void box.alert(
        'Das Bild ist zu klein',
        'Wählen Sie ein Bild mit einer Mindestgröße von 200 x 200 Pixeln.',
        { color: 'danger' },
      )
      valid = false
    }
    if (width > 5000 || height > 5000) {
      void box.alert(
        'Das Bild ist zu groß',
        'Wählen Sie ein Bild mit einer Maximalgröße von 5000 x 5000 Pixeln.',
        { color: 'danger' },
      )
      valid = false
    }
    const s =
      section ||
      getImageSection(width, height, this.props.width * 2, this.props.height * 2)
    if (s && valid) {
      this.selected = { id: uuid.v4(), src, width, height, section: s }
      this.mode = 'upload'
      this.transfer.type = 'pending'
      this.cache.set(this.selected.id, this.selected)
    } else {
      this.mode = null
      this.selected = null
    }
    // Upload immediately (with progress bar)
    // mode => null
    // model => uuid of currently uploaded image (after upload)
    // Lock interaction during upload (later maybe upload cancel)
    // Image size restriction: 5mb => image should be already compressed on the frontend?
  }

  private onEdit = async () => {
    const imageId = this.props.model.values[this.props.name]
    if (!imageId) {
      return
    }
    runInAction(() => {
      this.mode = 'loading'
      this.selected = null
      this.transfer.type = 'pending'
    })
    try {
      const source = axios.CancelToken.source()
      this.cancelDownload = () => source.cancel('Canceled by user')
      const response = await axios.get(
        `/api/${this.context.instance.id}/images/o${imageId}`,
        { cancelToken: source.token, responseType: 'arraybuffer' },
      )
      this.cancelDownload = null
      const section = jsonParse(response.headers['x-section'] ?? '')
      if (!isPlainObject(section)) {
        throw new Error('No x-section header transmitted.')
      }
      const src = arraybufferToBase64(
        response.data,
        response.headers['content-type'] ?? '',
      )
      const dimensions = await new Promise<{ width: number; height: number }>(
        (resolve, reject) => {
          const image = new Image()
          image.src = src
          image.onload = () => {
            resolve({ width: image.width, height: image.height })
          }
          image.onerror = () => {
            reject('Unsupported image format')
          }
        },
      )
      runInAction(() => {
        this.mode = 'editing'
        this.selected = {
          id: uuid.v4(),
          src,
          width: dimensions.width,
          height: dimensions.height,
          section: section as any,
        }
      })
    } catch (e) {
      void box.alert(
        'Bild kann derzeit nicht bearbeitet werden',
        'Originalbild konnte nicht abgerufen werden.',
        { color: 'danger' },
      )
    }
  }

  @action
  private onClose = () => {
    this.mode = null
    this.selected = null
    this.transfer.type = 'pending'
  }

  @action
  private onWebcam = () => (this.mode = 'webcam')

  @action
  private onDelete = () => {
    this.mode = null
    if (this.selected) {
      this.cache.delete(this.selected.id)
    }
    this.selected = null
    this.props.model.values[this.props.name] = null
    this.transfer.type = 'pending'
  }

  @action
  private onUploadError = () => {
    alert('Der Bildupload ist leider fehlgeschlagen')
    this.onClose()
    this.transfer.type = 'uploadError'
  }

  @action
  private onFinishUpload = (id: string) => {
    this.mode = null
    this.selected = null
    this.props.model.values[this.props.name] = id
  }

  private setFileInput = (input) => {
    this.fileInput = input
  }

  private handleFiles = async (e) => {
    const input = e.target
    const file = input.files[0]
    try {
      const { src, width, height } = await this.getImage(file)
      this.onSelect(src, width, height)
    } catch (e) {
      Bugsnag.notify(e as any)
    } finally {
      input.value = null
    }
  }

  private getImage = (file): Promise<{ src: string; width: number; height: number }> =>
    new Promise((resolve, reject) => {
      const imageType = /image.*/
      if (!file.type.match(imageType)) {
        void box.alert(
          'Dieses Bildformat wird nicht unterstützt',
          'Bitte verwenden Sie Bilder in den Formaten png oder jpg.',
          { color: 'danger' },
        )
        reject('Wrong mime type')
        return
      }

      if (file.size > 6000000) {
        void box.alert(
          'Die gewählte Datei ist zu groß',
          'Die Maximalgröße beträgt 6 MB. Bitte wählen Sie ein kleineres Bild.',
          { color: 'danger' },
        )
        reject('The selected image is too big')
        return
      }
      const reader = new FileReader()
      const image = new Image()
      reader.onload = () => {
        if (typeof reader.result !== 'string') {
          reject(
            'ImageArea reader.result should be a string because it was requested with .readAsDataURL',
          )
          return
        }
        image.src = reader.result
      }
      image.onload = () => {
        if (typeof reader.result !== 'string') {
          reject(
            'ImageArea reader.result should be a string because it was requested with .readAsDataURL',
          )
          return
        }
        resolve({ src: reader.result, width: image.width, height: image.height })
      }
      image.onerror = () => {
        void box.alert(
          'Das Bildformat konnte nicht erkannt werden',
          'Bitte wählen Sie ein anderes Bild oder speichern Sie das Bild vor dem Upload als png oder jpg.',
          { color: 'danger' },
        )
        reject('Unsupported image format')
      }
      reader.readAsDataURL(file)
    })

  render() {
    const imageId = this.props.model.values[this.props.name]
    return (
      <div
        className='bg-gray-800 rounded-md shadow overflow-hidden relative'
        style={{
          ...this.props.style,
          width: this.props.width,
          height: this.props.height,
        }}
      >
        {this.mode === 'loading' && <Spinner />}
        {this.mode === 'webcam' && (
          <WebcamCapture
            width={this.props.width}
            height={this.props.height}
            onSelect={this.onSelect}
            onClose={this.onClose}
          />
        )}
        {/* Placeholder */}
        {this.mode === null && !imageId && this.props.placeholder && (
          <img
            alt=''
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: this.props.width,
              height: this.props.height,
            }}
            src={this.props.placeholder}
          />
        )}
        {this.mode === null && !imageId && !this.props.disabled && (
          <>
            <RoundIcon
              onClick={this.onSelectFile}
              color='white'
              icon='fas fa-cloud-upload-alt'
              tooltip='Bild hochladen'
              style={{ position: 'absolute', right: 50, bottom: 10 }}
            />
            <RoundIcon
              onClick={this.onWebcam}
              color='white'
              icon='fas fa-camera'
              tooltip={{ text: 'Bild aufnehmen', offset: -100 }}
              style={{ position: 'absolute', right: 10, bottom: 10 }}
            />
          </>
        )}
        {this.mode === null && imageId && (
          <DisplayImage
            src={
              this.cache.get(imageId)?.src ||
              `/api/${this.context.instance.id}/images/p${imageId}`
            }
            cache={this.cache.get(imageId)}
            width={this.props.width}
            height={this.props.height}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            disabled={this.props.disabled}
          />
        )}
        {this.mode === 'upload' && this.selected && (
          <UploadImage
            scope={this.props.scope}
            image={this.selected}
            width={this.props.width}
            height={this.props.height}
            onFinish={this.onFinishUpload}
            onError={this.onUploadError}
            onCancel={this.onClose}
            transfer={this.transfer}
          />
        )}
        {this.mode === 'editing' && this.selected && (
          <EditImage
            image={this.selected}
            width={this.props.width}
            height={this.props.height}
            onSelect={this.onSelect}
            onCancel={this.onClose}
          />
        )}
        <UploadStripe state={this.transfer} width={this.props.width} />
        <input
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            height: 1,
            width: 1,
            opacity: 0,
          }}
          ref={this.setFileInput}
          type='file'
          multiple={false}
          accept='image/*'
          onChange={this.handleFiles}
        />
        {this.props.children}
      </div>
    )
  }
}
