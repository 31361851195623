export const EquipmentIcon: any = {
  Bad: <i className='fas fa-shower' />,
  Küche: <i className='fas fa-mug-hot' />,
  Barrierefrei: <i className='fas fa-wheelchair' />,
  Krisenzimmer: <i className='fas fa-bolt' />,
  Büro: <i className='fas fa-desktop' />,
  WC: <i className='fas fa-toilet-paper' />,
  Badewanne: <i className='fas fa-bath' />,
  Balkon: <i className='fas fa-store' />,
}
