import { IDocumentDocxTemplate } from 'contracts/general/interfaces/IDocumentDocxTemplate'
import { dayjs } from 'helpers/dayjs'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { LogDetails } from '../../../../../../LogTab/components/LogDetails'

interface Props {
  logs: IDocumentDocxTemplate['logs']
}

export const DocxTemplateLogs: React.FC<Props> = ({ logs }) => {
  const context = React.useContext(AppContext)
  const [selected, setSelected] = React.useState<number | null>(null)

  // Empty log preview
  if (logs.length === 0) {
    return (
      <div className='bg-white shadow-md rounded-md mt-6 p-4 flex flex-col gap-4'>
        <div className='text-lg'>Chronik</div>
        <div>
          <div className='text-sm text-gray-400'>Keine Einträge</div>
          <div className='rounded-md border-gray-300 border py-3'>
            <div className='m-auto tc'>
              <div
                className='relative inline-block flex justify-center max-w-xl'
                style={{ fontSize: '9rem', color: '#DFE4E8' }}
              >
                <i className='fas fa-tasks' aria-hidden='true' />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='bg-white shadow-md rounded-md mt-6 p-4 flex flex-col gap-4'>
      <div className='text-lg'>Chronik</div>
      <ul>
        {logs.map((log: IDocumentDocxTemplate['logs'][0], i: number) => {
          const at = dayjs(log.at)
          const thumbnail =
            context.permissions.resident_showChronicles <= 1
              ? getResidentImageSrc(context.instance.id, null, null, 'thumbnail')
              : getResidentImageSrc(
                  context.instance.id,
                  log.initiator?.imageId || null,
                  log.initiator?.sex || null,
                  'thumbnail',
                )
          const createdBy = log.initiator
            ? context.permissions.resident_showChronicles <= 1
              ? 'Ein Benutzer'
              : `${log.initiator.lastName}, ${log.initiator.firstName}`
            : 'Inuv System'
          return (
            <li
              key={i}
              className={`w-[376px] overflow-hidden relative group rounded-md cursor-pointer p-3 border-2 ${
                selected === i
                  ? 'border-blue-500 bg-gray-100 hover:bg-gray-50'
                  : 'border-transparent hover:bg-gray-100'
              } ${i + 1 === logs.length ? 'mb-4' : ''}`}
              onClick={() => setSelected(selected === i ? null : i)}
            >
              <div
                className={`relative overflow-hidden ${
                  i + 1 === logs.length ? 'pb-4' : 'pb-8'
                }`}
              >
                {i + 1 !== logs.length ? (
                  <span
                    className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
                    aria-hidden='true'
                  />
                ) : null}
                <div className='relative flex space-x-3'>
                  <div className='relative'>
                    <img
                      className='h-12 w-12 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white'
                      src={thumbnail}
                      alt={createdBy}
                    />
                  </div>
                  <div className='flex min-w-0 flex-1 justify-between space-x-4'>
                    <div className={selected === i ? 'overflow-hidden' : 'truncate'}>
                      <span className='mr-1 inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800'>
                        {at.format('DD.MM.YYYY')}
                      </span>
                      <span className='text-indigo-500'>{createdBy}</span>
                      <br />
                      <span className='text-black'>{log.label}</span>
                    </div>
                  </div>
                </div>
                {selected === i && (
                  <LogDetails view={log.details.view} changes={log.details.changes} />
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
