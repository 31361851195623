import * as React from 'react'
import { useNavigate } from 'react-router'

interface Props {
  url: string
}

export const EmployeeCommPreviewBackButton: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  return (
    <span
      onClick={() => navigate(props.url)}
      className={`cursor-pointer inline-flex xxl:hidden whitespace-nowrap items-center rounded-md px-2 py-1 text-xs font-medium text-white bg-indigo-500`}
    >
      <i className='fas fa-angle-left' />
      &nbsp; Zurück
    </span>
  )
}
