import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { getFileExtensionIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/helpers/getFileExtensionIcon'
import * as React from 'react'
import styles from '../styles.module.scss'
import { observer } from 'mobx-react'
import { getDiskSizeLabel } from 'contracts/general/helpers/getDiskSizeLabel'
import dayjs from 'dayjs'

interface Props {
  doc: IDocumentMetadata & { file: Blob }
}

@observer
export class SalarySlipErrorRow extends React.Component<Props, {}> {
  render() {
    const doc = this.props.doc
    return (
      <div className='relative'>
        {/* Select file */}
        <div className='flex bg-white shadow-md h-[70px] cursor-pointer mt-4'>
          <div className='flex-[0_0_70px] bg-center'>
            <img
              src={getFileExtensionIcon(doc.name)}
              alt=''
              style={{ height: 50, margin: 10 }}
            />
          </div>
          <div className='flex-auto'>
            <div className='flex'>
              <div
                className={styles.documentName}
                style={{ maxWidth: 400, color: 'red' }}
              >
                {doc.name}
              </div>
            </div>
            <div className={`text-gray-400 text-sm truncate ${styles.documentDetails}`}>
              {getDiskSizeLabel(doc.size)}
              &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              {dayjs(doc.createdAt).format('DD.MM.YYYY')}
              &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              <span className='text-red-500'>Keine Zuordnung gefunden</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
