import { residentFields } from 'contracts/residents/constants/residentFields'
import * as React from 'react'

interface Props {}

export const ResidentPlaceholderLegend: React.FC<Props> = (props) => {
  const [placeholders] = React.useState(() => residentFields.map((f) => f.label).sort())
  return (
    <div className='relative'>
      <div
        className='relative border border-gray-300 bg-white p-2 flex flex-wrap gap-1 max-h-[100px] overflow-x-none overflow-y-auto'
        style={{ borderRadius: '6px 0 0 6px' }}
      >
        {placeholders.map((p) => (
          <span
            className='inline-flex items-center rounded-md bg-gray-500 px-2 py-1 text-xs font-medium text-white'
            key={p}
          >{`{${p}}`}</span>
        ))}
      </div>
      <label
        className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400 truncate max-w-full'
        style={{ left: 9, top: -7 }}
      >
        Mögliche Platzhalter
      </label>
    </div>
  )
}
