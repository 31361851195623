export const DOCX_TEMPLATE_PREFIX = 'docx-template-'

export interface IDocumentTemplate {
  id: string
  instanceId: string
  folderIds: string[]
  component: string
  type: 'pdf' | 'docx' | 'xlsx'
  label: string
  thumbnailId: string
}
