import * as React from 'react'
import { XIcon } from '@heroicons/react/outline'
import { observer } from 'mobx-react-lite'
import { Button } from 'components/Form/components/Button'
import { IDocumentDocxTemplate } from 'contracts/general/interfaces/IDocumentDocxTemplate'
import { EditDocxTemplate } from './components/EditDocxTemplate'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { Collection, hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { DocumentDocxTemplateCreateValidator } from 'contracts/general/validators/DocumentDocxTemplateCreateValidator'
import { AppContext } from 'services/connection/models/AppContext'
import { toast } from 'react-toastify'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { useCollection } from 'helpers/useCollection'
import { getDocxTemplateImage } from './helpers/getDocxTemplateImage'
import preview from './preview.png'
import { RoundIcon } from 'components/RoundIcon'

interface Props {
  onClose: () => void
  folders: Collection<IDocumentFolder>
}

export const TemplateManagementDialog: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const templates = useCollection<IDocumentDocxTemplate>(
    `/api/${context.instance.id}/documents/docxTemplates`,
    { readOnly: true },
  )
  const [saving, setSaving] = React.useState(false)
  const [selected, setSelected] = React.useState<Model<IDocumentDocxTemplate> | null>(
    null,
  )

  function setNewTemplate() {
    setSelected(
      new Model<IDocumentDocxTemplate>(
        {
          id: '',
          instanceId: '0',
          thumbnailId: null,
          documentId: null as any,
          folderIds: (props.folders.resources || []).map((r) => r.id).join(','),
          label: '',
          notes: '',
          placeholders: '',
          logs: [],
          createdAt: '',
          deletedAt: null,
        },
        DocumentDocxTemplateCreateValidator,
      ),
    )
  }

  async function onSubmit() {
    if (!selected || saving) {
      return
    }
    if (!selected.isValid()) {
      selected.setFocusToLeftTopmostInvalidField()
      toast.error('Bitte füllen Sie die rot markierten Felder aus.')
      return
    }
    try {
      setSaving(true)
      let isNew = !selected.values.id
      const data = {
        instanceId: context.instance.id,
        thumbnailId: selected.values.thumbnailId,
        documentId: selected.values.documentId,
        folderIds: selected.values.folderIds,
        label: selected.values.label,
        notes: selected.values.notes,
      }
      if (isNew) {
        await hermes.create(`/api/${context.instance.id}/documents/docxTemplates`, data)
      } else {
        await hermes.update(
          `/api/${context.instance.id}/documents/docxTemplates/${selected.values.id}`,
          data,
        )
      }
      setSelected(null)
      setSaving(false)
    } catch (_e) {
      setSaving(false)
      toast.error('Beim Speichern der Vorlage ist ein Fehler aufgetreten.')
    }
  }

  async function onDelete(id: string) {
    const confirmed = window.confirm('Möchten Sie diese Vorlage wirklich löschen?')
    if (confirmed) {
      try {
        await hermes.delete(`/api/${context.instance.id}/documents/docxTemplates/${id}`, {
          immediately: true,
        })
        toast.success('Die Vorlage wurde gelöscht.')
      } catch (_e) {
        toast.error('Beim Löschen der Vorlage ist ein Fehler aufgetreten')
      }
    }
  }

  return (
    <div>
      <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
        <button
          type='button'
          className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          onClick={() => props.onClose()}
        >
          <span className='sr-only'>Close</span>
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </div>
      <div className='text-xl text-gray-600 mb-4'>
        {selected && !selected.id
          ? 'Dokumentvorlage erstellen'
          : 'Dokumentvorlagen bearbeiten'}
      </div>
      <div className='bg-gray-100 border-t border-gray-300 -mx-6 p-6'>
        {selected && <EditDocxTemplate template={selected} folders={props.folders} />}
        {!selected && (
          <div className='relative'>
            <div className='flex flex-wrap gap-6'>
              {templates.resources?.map((template) => {
                if (!template.data) {
                  return null
                }
                return (
                  <div
                    key={template.id}
                    className='relative group w-[192px] h-[276px] cursor-pointer rounded-md shadow-md overflow-hidden relative hover:border-2 hover:border-blue-500 hover:opacity-75'
                    onClick={() =>
                      setSelected(
                        new Model(template.data, DocumentDocxTemplateCreateValidator),
                      )
                    }
                  >
                    <img
                      width='192'
                      height='276'
                      src={getDocxTemplateImage(
                        template.data.thumbnailId,
                        template.data.instanceId,
                      )}
                      className='w-full min-h-[275px]'
                      alt={template.data.label}
                    />
                    <div className='absolute bottom-0 p-2 bg-gray-900 opacity-50 text-white h-[64px] w-full hyphens-manual'>
                      {template.data.label}
                    </div>
                    <RoundIcon
                      classNameContainer='hidden group-hover:block'
                      style={{ position: 'absolute', top: 5, right: 5 }}
                      icon='fas fa-trash'
                      color='danger'
                      onClick={(event) => {
                        event.stopPropagation()
                        onDelete(template.id)
                      }}
                    />
                  </div>
                )
              })}

              <div
                className='w-[192px] h-[276px] cursor-pointer rounded-md shadow-md overflow-hidden relative hover:border-2 hover:border-blue-500 hover:opacity-75'
                onClick={setNewTemplate}
              >
                <img
                  width='192'
                  height='276'
                  src={preview}
                  className='w-full min-h-[275px]'
                  alt='Neues Template'
                />
                <div className='text-center absolute bottom-0 p-2 bg-blue-500 text-white h-[64px] w-full hyphens-manual'>
                  Neues Template
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='pt-4 -mb-4 pb-4 -mx-6 px-6 flex gap-2 justify-end sticky bottom-0 bg-white border-t border-gray-300'>
        {selected && (
          <Button
            disabled={saving}
            color='secondary'
            onClick={() => setSelected(null)}
            className='mr-auto'
          >
            Zurück
          </Button>
        )}
        <Button color='secondary' onClick={props.onClose} outline>
          Schließen
        </Button>
        {selected && (
          <Button color='primary' onClick={onSubmit}>
            Speichern
          </Button>
        )}
      </div>
      {saving && <DialogOverlaySpinner opaque center sticky />}
    </div>
  )
})
