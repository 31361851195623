import { isLfgInstance, isMucInstance } from '../../general/helpers/instanceIds'

export function getRoomEquipmentOptions(instanceId: string): string[] {
  if (isMucInstance(instanceId)) {
    return ['Bad', 'Küche', 'Barrierefrei', 'Krisenzimmer', 'Büro']
  }
  if (isLfgInstance(instanceId)) {
    return ['Bad', 'WC', 'Badewanne', 'Küche', 'Balkon', 'Barrierefrei']
  }
  return ['Bad', 'Küche', 'Barrierefrei']
}
