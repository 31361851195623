import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { InputDropdownOption } from 'components/Form/components/InputDropdown'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'
import { observer } from 'mobx-react'
import * as React from 'react'
import { NewSalarySlipTopic } from './components/NewSalarySlipTopic'
import { NewSalarySummaryReportTopic } from './components/NewSalarySummaryReportTopic'
import { useNavigate } from 'react-router'

interface Props {
  type: IEmployeeCommTopic['type']
  company: InputDropdownOption
  onClose: () => void
}

const titles = {
  'employee message': 'Neue Mitarbeiternachricht',
  'summarized salary report': 'Lohnsteuerbescheinigung Sammelupload',
  'salary slip': 'Gehaltszettel Sammelupload',
}

export const NewTopicDialog: React.FC<Props> = observer((props) => {
  const navigate = useNavigate()
  const onClose = (url?: string) => {
    if (url) {
      navigate(url)
    }
    props.onClose()
  }

  return (
    <div>
      <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
        <button
          type='button'
          className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          onClick={() => props.onClose()}
        >
          <span className='sr-only'>Close</span>
          <XIcon className='h-6 w-6' aria-hidden='true' />
        </button>
      </div>

      <div className='text-left'>
        <Dialog.Title
          as='h3'
          className='text-lg leading-6 font-medium text-gray-900 mb-4'
        >
          {titles[props.type]}
        </Dialog.Title>
      </div>

      {props.type === 'salary slip' && (
        <NewSalarySlipTopic company={props.company} onClose={onClose} />
      )}
      {props.type === 'summarized salary report' && (
        <NewSalarySummaryReportTopic company={props.company} onClose={onClose} />
      )}
    </div>
  )
})
