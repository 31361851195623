import * as React from 'react'
import { Fragment } from 'react'
import { Dialog as UiDialog, Transition } from '@headlessui/react'

interface Props {
  size?: 'md' | 'lg' | 'xl'
  open: boolean
  setOpen: (open: boolean) => void
}

const sizeMap = {
  md: 'w-dialogmd',
  lg: 'w-dialoglg',
  xl: 'w-dialoglg xl:w-dialogxl',
}

export const Dialog: React.FC<Props> = (props) => {
  function close(e) {
    if ((e.target.id || '').startsWith('headlessui-dialog-panel')) {
      props.setOpen(false)
    }
  }

  return (
    <Transition show={props.open} as={Fragment} appear>
      <UiDialog
        onClose={props.setOpen}
        as='div'
        className='fixed z-50 inset-0 overflow-auto'
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/60' />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <UiDialog.Panel className='my-6' onClick={close}>
            <div
              className={`relative block items-end justify-center bg-white rounded-lg text-left ${
                sizeMap[props.size || 'lg']
              } mx-auto`}
            >
              {props.children}
            </div>
          </UiDialog.Panel>
        </Transition.Child>
      </UiDialog>
    </Transition>
  )
}
