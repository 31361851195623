import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { getFileExtensionIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/helpers/getFileExtensionIcon'
import * as React from 'react'
import styles from '../styles.module.scss'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { TransferState } from 'components/Form/components/InputImage/components/UploadStripe'
import { action, makeObservable, observable, runInAction } from 'mobx'
import axios, { AxiosProgressEvent } from 'axios'
import { RoundIcon } from 'components/RoundIcon'

const trashStyle = {
  position: 'absolute',
  top: 21,
  right: 21,
  background: '#FD2547',
  color: 'white',
}

const statusColors = {
  uploaded: 'bg-green-500',
  uploadError: 'bg-red-500',
  aborted: 'bg-red-500',
}

interface Props {
  doc: IDocumentMetadata & { file: Blob }
  onUploaded?: (doc: IDocumentMetadata & { file: Blob }) => void
}

@observer
export class SalarySummaryReportUploadRow extends React.Component<Props, {}> {
  static contextType = AppContext
  private cancelUpload: (() => void) | null = null
  private mounted = true
  @observable private upload: TransferState | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.onUpload()
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelUpload?.()
  }

  private onUpload = async () => {
    const file = this.props.doc.file
    const name = this.props.doc.name
    const form = new FormData()
    form.append('file', file)
    form.append('scope', 'summarized salary report')
    form.append('category', this.props.doc.category) // Encodes topicId
    form.append('userId', this.props.doc.userId || '')
    form.append('name', name)

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    runInAction(() => {
      this.upload = { progress: 0.05, type: 'uploading' }
    })
    const uploadPromise = axios.post(
      `/api/${this.context.instance.id}/documents/files`,
      form,
      {
        cancelToken: source.token,
        onUploadProgress: action((event: AxiosProgressEvent) => {
          if (event.total && event.loaded / event.total <= 1 && this.upload) {
            // Start at 5% to visualize pending upload for files that are scheduled to upload.
            this.upload.progress = 0.05 + 0.95 * (event.loaded / event.total)
          }
        }),
      },
    )
    try {
      await uploadPromise
      if (!this.mounted) {
        return
      }
      runInAction(() => {
        if (this.upload) {
          this.upload.progress = 1
          this.upload.type = 'uploaded'
          this.props.doc.validTillDate = 'uploaded' // Use this field as a status indicator for the outer component
        }
        // this.attachment!.id = response.data.id
        this.props.onUploaded?.(this.props.doc)
      })
    } catch (e) {
      runInAction(() => (this.upload = null))
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        // Already handeled
      } else {
        alert('Beim Upload ist ein Fehler aufgetreten.')
      }
    }

    this.cancelUpload = null
  }

  render() {
    const doc = this.props.doc
    return (
      <div className='relative'>
        {/* Select file */}
        <div className='flex bg-white shadow-md h-[70px] cursor-pointer mt-4'>
          <div className='flex-[0_0_70px] bg-center'>
            <img
              src={getFileExtensionIcon(doc.name)}
              alt=''
              style={{ height: 50, margin: 10 }}
            />
          </div>
          <div className='flex-auto'>
            <div className='flex'>
              <div className={styles.documentName}>{doc.name}</div>
              <div className='flex-auto' style={{ padding: '13px 13px 0 10px' }}>
                <div
                  className={`${
                    statusColors[this.upload?.type || ''] || 'bg-blue-500'
                  } pl-1 text-white rounded-md ease-in-out duration-200 transition-width overflow-hidden`}
                  style={{
                    height: 24,
                    width: `${(this.upload?.progress || 0) * 100}%`,
                  }}
                >
                  {this.upload?.progress
                    ? `${(this.upload.progress * 100).toFixed(0)}%`
                    : ''}
                </div>
              </div>
            </div>
            <div
              className={`text-gray-400 text-sm truncate max-w-[370px] ${styles.documentDetails}`}
            >
              {doc.notes}
              &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              {doc.uploadUser /* This field is used for the assigned employee */}
            </div>
          </div>
        </div>

        {this.upload?.progress !== 1 && (
          <RoundIcon
            tooltip='Upload abbrechen'
            icon='fas fa-times'
            onClick={(event) => {
              event.stopPropagation()
              this.cancelUpload?.()
            }}
            style={trashStyle}
          />
        )}
      </div>
    )
  }
}
