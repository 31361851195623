import * as React from 'react'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { Button } from '../Button'
import { action, makeObservable } from 'mobx'

interface Props {
  model: Model<any>
  name: string
  onSelect?: (year: number) => void
  className?: string
  minYear?: number
  maxYear?: number
  disabled?: boolean
}

@observer
export class InputYear extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private leftButton = () => {
    const year = this.props.model.values[this.props.name]
    if (this.props.minYear && year <= this.props.minYear) {
      return
    }
    this.props.model.values[this.props.name] = year - 1
    this.props.onSelect?.(year - 1)
  }

  @action
  private rightButton = () => {
    const year = this.props.model.values[this.props.name]
    if (this.props.maxYear && year >= this.props.maxYear) {
      return
    }
    this.props.model.values[this.props.name] = year + 1
    this.props.onSelect?.(year + 1)
  }

  render() {
    const year = this.props.model.values[this.props.name]

    return (
      <div className={`flex ${this.props.className || ''}`}>
        <div className='flex-content'>
          <Button
            color={this.props.disabled ? 'secondary' : 'primary'}
            style={{ width: 38, borderRadius: '6px 0 0 6px' }}
            onClick={this.leftButton}
            disabled={
              !!this.props.disabled ||
              (!!this.props.minYear && year <= this.props.minYear)
            }
          >
            <i className='fa fa-angle-left gray' />
          </Button>
        </div>
        <div
          className='px-3 flex-auto truncate text-center border-t border-b border-gray-300'
          style={{ lineHeight: '36px' }}
        >
          {year}
        </div>
        <div className='flex-content'>
          <Button
            color={this.props.disabled ? 'secondary' : 'primary'}
            style={{ width: 38, borderRadius: '0 6px 6px 0' }}
            onClick={this.rightButton}
            disabled={
              !!this.props.disabled ||
              (!!this.props.maxYear && year >= this.props.maxYear)
            }
          >
            <i className='fa fa-angle-right gray' />
          </Button>
        </div>
      </div>
    )
  }
}
