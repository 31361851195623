import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { InputDropdownOption } from 'components/Form/components/InputDropdown'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { ICompany } from 'contracts/companies/interfaces/ICompany'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { storage } from 'services/storage'
import { TopicList } from './components/TopicList'
import { ButtonDropdown } from 'components/ButtonDropdown'
import { box } from 'services/box'
import { NewTopicDialog } from './components/NewTopicDialog'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'

interface Props {
  navigate: (url: string) => void
  filter: Model<{ companyId: string; page: string }>
  selected?: string
}

@observer
export class EmployeeCommunicationSidebar extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private companies: InputDropdownOption[] | null = null
  private readonly types: InputSelectOption[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    /*if (context.permissions.menu_employeeCommunication >= 2) {
      this.types.push({ label: 'Nachricht an Mitarbeiter...', value: 'employee message' })
    }*/
    if (context.permissions.employee_salarySheets >= 2) {
      this.types.push({ label: 'Abrechnung / Gehaltszettel...', value: 'salary slip' })
      this.types.push({
        label: 'Lohnsteuerbescheinigungen...',
        value: 'summarized salary report',
      })
    }
    makeObservable(this)
  }

  componentDidMount() {
    this.fetchCompanies()
  }

  private fetchCompanies = async () => {
    const response = await hermes.indexOnceNew<ICompany>(
      `/api/${this.context.instance.id}/companies`,
    )
    let topic: IEmployeeCommTopic | null = null
    try {
      if (this.props.selected) {
        topic = await hermes.getOnceNew<IEmployeeCommTopic>(
          `/api/${this.context.instance.id}/employeeComm/topics/${this.props.selected}`,
        )
      }
    } catch (_e) {
      /* */
    }
    runInAction(() => {
      const lastCompanyId =
        topic?.companyId || storage.get(`employee_comm_company_${this.context.user.id}`)
      const selected = response.find((company) => company.id === lastCompanyId)
      if (selected) {
        this.props.filter.values.companyId = selected.id
      } else {
        this.props.filter.values.companyId = response[0]?.id || ''
      }
      this.companies = response.map((company) => ({
        label: company.name,
        value: company.id,
      }))
    })
  }

  private storeSelectedCompany = () => {
    this.props.navigate(`/employee/communication`)
    storage.set(
      `employee_comm_company_${this.context.user.id}`,
      this.props.filter.values.companyId,
    )
  }

  private onCreate = (option: InputDropdownOption) => {
    const company = this.companies?.find(
      (c) => c.value === this.props.filter.values.companyId,
    )
    if (!company) {
      box.alert('Neue Kommunikation', 'Bitte wählen Sie ein Unternehmen aus.')
      return
    }
    const promise = box.custom(
      <NewTopicDialog
        onClose={() => promise.close()}
        type={option.value as any}
        company={company}
      />,
      { context: this.context },
    )
  }

  render() {
    return (
      <div>
        {/* Company selection */}
        <InputSelect
          onChange={this.storeSelectedCompany}
          options={this.companies || []}
          model={this.props.filter}
          name='companyId'
          disabled={!this.companies}
        />

        {/* Create new topic */}
        {this.types.length > 0 && (
          <div className='relative mt-4'>
            <ButtonDropdown
              align='left'
              width={230}
              options={this.types}
              onSelect={this.onCreate}
              color='primary'
              block
              disabled={!this.companies}
            >
              Neu <i className='fas fa-caret-down' />
            </ButtonDropdown>
          </div>
        )}

        {/* Topic list */}
        {this.props.filter.values.companyId && (
          <TopicList filter={this.props.filter} selected={this.props.selected} />
        )}
      </div>
    )
  }
}
