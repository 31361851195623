import { Collection, hermes } from '@byll/hermes'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { Spinner } from 'components/Spinner'
import { IEmployeeCommThread } from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { RerunSalarySheetCheck } from './components/RerunSalarySheetCheck'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'
import { isValidSalarySlip } from 'contracts/employeeComm/helpers/isValidSalarySlip'
import { Button } from 'components/Form/components/Button'
import { SendAllValidSalarySheets } from './components/SendAllValidSalarySheets'
import { box } from 'services/box'
import { EmployeeSalarySheetPreview } from 'modules/EmployeeCommunication/components/EmployeeCommunicationContent/components/SalarySlipContent/components/EmployeeSalarySheetPreview'
import { dayjs } from 'helpers/dayjs'
import { RoundIcon } from 'components/RoundIcon'

const statusMap = {
  sent: { color: 'bg-blue-500 text-white', text: 'Versendet' },
  'send error': { color: 'bg-red-500 text-white', text: 'Versandfehler' },
  received: { color: 'bg-green-500 text-white', text: 'Empfangen' },
}

interface Props {
  topic: IEmployeeCommTopic
  threads: Collection<IEmployeeCommThread>
  onSelect: (threadId: string | null) => void
  threadId: string | null // currently selected
}

@observer
export class SalarySlipContent extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private sendAll = async () => {
    const salarySheets =
      this.props.threads.resources
        ?.map((r) => r.data!)
        .filter((t) => t?.status === 'draft') ?? []
    if (salarySheets.length === 0) {
      alert('Es liegen keine Gehaltszettel zum Versenden vor.')
      return
    }
    if (!salarySheets.some((s) => isValidSalarySlip(s.extra))) {
      alert('Es befinden sich keine versandfertigen Gehaltszettel in der Liste.')
      return
    }
    const promise = box.custom(
      <SendAllValidSalarySheets
        onClose={() => promise.close()}
        salarySheets={salarySheets}
      />,
      { context: this.context },
    )
  }

  private deleteThread = async (threadId: string) => {
    const confirmed = await box.alert(
      'Gehaltszettel löschen',
      'Möchten Sie diesen Gehaltszettel wirklich löschen?',
      { color: 'danger', confirm: 'Ja, löschen', cancel: 'Abbrechen' },
    )
    if (confirmed) {
      try {
        await hermes.delete(
          `/api/${this.context.instance.id}/employeeComm/topics/${this.props.topic.id}/threads/${threadId}`,
          { immediately: true },
        )
        if (this.props.threadId === threadId) {
          this.props.onSelect(null)
        }
      } catch (_e) {
        await box.alert(
          'Fehler',
          'Beim Löschen des Gehaltszettels ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator.',
          { color: 'danger' },
        )
      }
    }
  }

  render() {
    if (this.props.threads.error) {
      const error = this.props.threads.error?.id || ''
      return (
        <div className='flex-auto relative'>
          <div className='absolute left-0 right-6 bottom-6 top-6 overflow-x-hidden overflow-y-auto flex'>
            {error === RequestPendingError.id && <Spinner delay />}
            {error === NotFoundError.id && (
              <Callout
                title='Nachricht nicht gefunden'
                icon='far fa-lemon text-yellow-400'
              />
            )}
            {error !== RequestPendingError.id && error !== NotFoundError.id && (
              <LoadingError title='Nachrichten konnten nicht geladen werden' />
            )}
          </div>
        </div>
      )
    }

    if (!this.props.threads.resources || this.props.threads.resources.length === 0) {
      return (
        <div className='flex-auto relative'>
          <div className='absolute left-0 right-6 bottom-6 top-6 overflow-x-hidden overflow-y-auto flex'>
            <Callout
              title='Keine Gehaltszettel vorhanden'
              subtitle='Klicken Sie auf den Button "Neu", um Gehaltszettel hochzuladen.'
              icon='fas fa-folder-open'
            />
          </div>
        </div>
      )
    }

    return (
      <>
        <div
          className={`flex-auto relative ${
            this.props.threadId ? 'hidden xxl:block' : ''
          }`}
        >
          <div className='absolute left-0 right-0 top-10 text-gray-500 text-sm'>
            {this.props.topic.label}{' '}
            {this.props.threads.resources && (
              <Button
                style={{ padding: '0 4px', margin: '0 8px' }}
                color='secondary'
                outline
                onClick={this.sendAll}
              >
                Sammelversand
              </Button>
            )}
            <RerunSalarySheetCheck topicId={this.props.topic.id} />
          </div>

          <div className='absolute left-0 right-0 bottom-6 top-[72px] overflow-x-hidden overflow-y-auto'>
            {this.props.threads.resources.length > 0 && (
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                {this.props.threads.resources.map((t) => {
                  if (!t.data) {
                    return null
                  }
                  const valid = isValidSalarySlip(t.data.extra)
                  return (
                    <div
                      key={t.id}
                      className={`group overflow-hidden bg-white rounded-md shadow p-2 h-[70px] border-2 cursor-pointer relative ${
                        this.props.threadId === t.id
                          ? 'border-indigo-500'
                          : 'border-white'
                      }`}
                      onClick={() => this.props.onSelect(t.id)}
                    >
                      <div className='truncate'>
                        {t.data.employee.firstName} {t.data.employee.lastName}
                        {t.data.contract && (
                          <span className='text-sm text-gray-500'>
                            &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                            {t.data.contract.type}
                            &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                            {`${dayjs(t.data.contract.joinDate).format('DD.MM.YYYY')} - ${
                              t.data.contract.resignDate
                                ? dayjs(t.data.contract.resignDate).format('DD.MM.YYYY')
                                : 'unbefristet'
                            }`}
                          </span>
                        )}
                      </div>
                      {t.data.status === 'draft' && (
                        <div className='truncate'>
                          {!valid && (
                            <span className='inline-flex items-center rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white'>
                              Nicht versandfertig
                            </span>
                          )}
                          {valid && (
                            <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10'>
                              Versandfertig
                            </span>
                          )}
                        </div>
                      )}
                      {t.data.status !== 'draft' && (
                        <div className='truncate'>
                          <span
                            className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${statusMap[
                              t.data.status
                            ]?.color}`}
                          >
                            {statusMap[t.data.status]?.text}
                          </span>
                        </div>
                      )}
                      <RoundIcon
                        onClick={(e) => {
                          e.stopPropagation()
                          this.deleteThread(t.id)
                        }}
                        classNameContainer='hidden group-hover:block'
                        icon='fas fa-trash'
                        color='danger'
                        style={{ position: 'absolute', top: 3, right: 3 }}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        {this.props.threadId ? (
          <div className='flex-[0_0_864px] mx-auto relative'>
            <EmployeeSalarySheetPreview
              key={`${this.props.topic.id}/${this.props.threadId}`}
              topic={this.props.topic}
              threadId={this.props.threadId}
            />
          </div>
        ) : (
          <div className='flex_[0_0_0]' />
        )}
      </>
    )
  }
}
