import { IDocumentDocxTemplate } from 'contracts/general/interfaces/IDocumentDocxTemplate'
import { observer } from 'mobx-react'
import * as React from 'react'
import { InputImage } from 'components/Form/components/InputImage'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { Collection } from '@byll/hermes'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { InputDocument } from 'components/Form/components/InputDocument'
import { ResidentPlaceholderLegend } from './ResidentPlaceholderLegend'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { DocxTemplateLogs } from './DocxTemplateLogs'

interface Props {
  template: Model<IDocumentDocxTemplate>
  folders: Collection<IDocumentFolder>
}

@observer
export class EditDocxTemplate extends React.Component<Props, {}> {
  private readonly folders: InputMultiSelectOption[]

  constructor(props: Props) {
    super(props)
    this.folders = []
    for (const res of props.folders.resources || []) {
      if (res.data) {
        this.folders.push({ id: res.id, label: res.data.label })
      }
    }
  }

  render() {
    return (
      <div className='flex'>
        {/* Image */}
        <div className='flex-[0_0_192px]'>
          <InputImage
            model={this.props.template}
            name='thumbnailId'
            width={192}
            height={276}
            scope='document template thumbnail'
          />
        </div>

        {/* Form */}
        <div className='flex-auto ml-6' id={this.props.template.id}>
          {/* Properties */}
          <div className='bg-white shadow-md rounded-md p-4 flex flex-col gap-4'>
            <div className='text-lg'>Dokumentvorlage</div>
            <InputText model={this.props.template} name='label' label='Bezeichnung' />
            <InputMultiSelect
              error={
                !this.props.template.values.folderIds &&
                !!this.props.template.touched.get('folderIds')
              }
              model={this.props.template}
              name='folderIds'
              label='Freigegebene Ordner'
              options={this.folders}
            />
            <InputDocument
              model={this.props.template}
              name='documentId'
              label='Template Datei'
              scope='word template'
              accept='.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            />
            <ResidentPlaceholderLegend />
            <InputTextarea
              rows={3}
              model={this.props.template}
              name='notes'
              label='Notizen'
            />
          </div>

          {/* Logs */}
          <DocxTemplateLogs logs={this.props.template.values.logs} />
        </div>
      </div>
    )
  }
}
