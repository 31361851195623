import { hermes } from '@byll/hermes'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import {
  IBooking,
  INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE,
} from 'contracts/residents/interfaces/IBooking'
import { Dayjs } from 'dayjs'

export async function getNextBillingStatus(
  at: Dayjs,
  instanceId: string,
  nextRoom?: IRoom,
  bookings?: IBooking[],
): Promise<IBooking['extra']['billing'] & { warning: string | null }> {
  /*const billing: IBooking['extra']['billing'] = {
    status: '',
    category: '',
    dateOfNoticeCreation: null,
    avDate: null,
  }*/
  let lastBooking: IBooking | null = null
  if (!bookings || !nextRoom) {
    return {
      status: '',
      category: '',
      dateOfNoticeCreation: null,
      avDate: null,
      warning: null,
    }
  }

  for (let i = bookings.length - 1; i >= 0; i--) {
    if (
      bookings[i].type === 'info' &&
      bookings[i].reason === INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE
    ) {
      lastBooking = null
    }
    if (bookings[i].type !== 'internal-residence') {
      continue
    }
    lastBooking = bookings[i]
    //billing.status = bookings[i].extra.billing?.status || ''
    //billing.category = bookings[i].extra.billing?.category || ''
    //billing.dateOfNoticeCreation = bookings[i].extra.billing?.dateOfNoticeCreation || null
    //billing.avDate = bookings[i].extra.billing?.avDate || null
    if (at.isBefore(bookings[i].beginAt)) {
      break
    }
  }

  const lastStatus = lastBooking?.extra.billing?.status || ''
  if (lastBooking?.roomId) {
    const lastRoom = await hermes.getOnceNew<IRoom>(
      `/api/${instanceId}/accommodations/rooms/${lastBooking?.roomId}`,
      { getFromStoreIfPossible: true },
    )
    // Same building?
    if (lastRoom?.buildingId === nextRoom.buildingId) {
      return {
        status:
          lastStatus === 'Statuswechsler' ||
          lastStatus === 'Selbstzahler' ||
          lastStatus === 'Statuswechsler (volljährig)' ||
          lastStatus === 'Selbstzahler (volljährig)'
            ? lastStatus
            : '',
        category: nextRoom.typeOfUse,
        dateOfNoticeCreation:
          lastRoom.typeOfUse === nextRoom.typeOfUse
            ? lastBooking.extra.billing?.dateOfNoticeCreation || null
            : null,
        warning:
          lastRoom.typeOfUse !== nextRoom.typeOfUse
            ? 'Die bisherige Zimmerkategorie unterscheidet sich von der Zimmerkategorie des neuen Raumes. Möchten Sie diesen Bewohner wirklich in den gewählten Raum verlegen?'
            : null,
        avDate: null,
      }
    }
  }
  // Different building (or first booking after Gebührenpflichtende)
  return {
    status:
      lastStatus === 'Statuswechsler' ||
      lastStatus === 'Selbstzahler' ||
      lastStatus === 'Statuswechsler (volljährig)' ||
      lastStatus === 'Selbstzahler (volljährig)'
        ? lastStatus
        : 'Aufnahmeverfügung fehlt',
    category: nextRoom.typeOfUse,
    dateOfNoticeCreation: null,
    warning: null,
    avDate: null,
  }
}
