import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import axios, { AxiosProgressEvent } from 'axios'
import { AppContext } from 'services/connection/models/AppContext'
import { TransferState, UploadStripe } from '../InputImage/components/UploadStripe'
import { RoundIcon } from 'components/RoundIcon'
import { download } from 'helpers/download'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { hermes } from '@byll/hermes'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { ConflictError } from 'contracts/errors/HermesErrors'

interface Props {
  name: string
  model: Model<any>
  scope:
    | 'employee'
    | 'booking'
    | 'cost coverage'
    | 'floor plan'
    | 'migration file'
    | 'input block'
    | 'word template'
  label?: string
  className?: string
  style?: any
  disabled?: boolean
  children?: Element
  id?: string
  accept?: string
  preview?: boolean
  onUpload?: (event: 'started' | 'finished' | 'failed') => void
}

@observer
export class InputDocument extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id
  @observable private fileName = ''
  @observable private upload: TransferState | null = null
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    this.id = props.id || uniqueId('upload-')
    makeObservable(this)
  }
  private cancelUpload: (() => void) | null = null
  private mounted = true

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.props.model.values[this.props.name],
        async (id) => {
          if (!id) {
            runInAction(() => {
              this.fileName = ''
              this.upload = null
            })
            return
          }
          try {
            const meta = await hermes.getOnceNew<IDocumentMetadata>(
              `/api/${this.context.instance.id}/documents/metadata/${id}`,
            )
            runInAction(() => (this.fileName = meta.name))
          } catch (_e) {
            runInAction(() => (this.fileName = '?'))
          }
        },
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    this.mounted = false
    this.cancelUpload?.()
    dispose(this.disposers)
  }

  private triggerFileSelect = () => document.getElementById(this.id + '-input')?.click()

  @action private onDelete = () => {
    if (!window.confirm('Möchten Sie diese Datei wirklich löschen?')) {
      return
    }
    this.props.model.values[this.props.name] = null
    this.fileName = ''
    this.upload = null
  }

  private onPreview = () => {
    window.open(`/documents/${this.props.model.values[this.props.name]}`, '_blank')
  }

  private onDownload = () => {
    download(
      `/api/${this.context.instance.id}/documents/files/${
        this.props.model.values[this.props.name]
      }`,
    )
  }

  private onSelectFile = async (e) => {
    const file = e.target.files[0]
    const name = file.name
    const form = new FormData()
    form.append('file', e.target.files[0])
    form.append('scope', this.props.scope)
    form.append('name', name)

    const source = axios.CancelToken.source()
    this.cancelUpload = () => source.cancel('Canceled by user')
    runInAction(() => {
      this.upload = { progress: 0.05, type: 'uploading' }
      this.fileName = name
    })
    this.props.onUpload?.('started')
    const uploadPromise = axios.post(
      `/api/${this.context.instance.id}/documents/files`,
      form,
      {
        cancelToken: source.token,
        onUploadProgress: action((event: AxiosProgressEvent) => {
          if (event.total && event.loaded / event.total <= 1 && this.upload) {
            // Start at 5% to visualize pending upload for files that are scheduled to upload.
            this.upload.progress = 0.05 + 0.95 * (event.loaded / event.total)
          }
        }),
      },
    )
    try {
      const response: any = await uploadPromise
      if (!this.mounted) {
        return
      }
      runInAction(() => {
        if (this.upload) {
          this.upload.progress = 1
          this.upload.type = 'uploaded'
        }
        this.props.model.values[this.props.name] = response.data.id
      })
      this.props.onUpload?.('finished')
    } catch (e: any) {
      runInAction(() => {
        this.fileName = ''
        this.upload = null
      })
      if (typeof e === 'object' && (e as any)?.message === 'Canceled by user') {
        // Already handeled
      } else {
        console.log(e)
        alert(
          e?.response?.data?.id === ConflictError.id
            ? e.response.data.message
            : 'Beim Upload ist ein Fehler aufgetreten.',
        )
      }
      this.props.onUpload?.('failed')
    }

    this.cancelUpload = null
    const input: HTMLInputElement | null = document.getElementById(
      this.id + '-input',
    ) as any
    if (input) {
      input.value = ''
    }
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const { name, model, label, className, children, style, disabled, accept, preview } =
      this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7 }}
          >
            {label}
          </label>
        )}
        <input
          type='text'
          name={name}
          className={innerClassName}
          maxLength={255}
          readOnly
          disabled={disabled}
          value={this.fileName}
          id={this.id}
          style={style}
        />
        {!model.values[name] && !this.upload && !disabled && (
          <span
            onClick={this.triggerFileSelect}
            className='absolute inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 hover:bg-indigo-200 cursor-pointer'
            style={{ top: 9, left: 12 }}
          >
            Datei wählen...
          </span>
        )}
        <input
          id={this.id + '-input'}
          type='file'
          className='hidden'
          multiple={false}
          onChange={this.onSelectFile}
          disabled={disabled}
          accept={accept}
        />
        {model.values[name] && preview && (
          <RoundIcon
            tooltip='Vorschau'
            color='white'
            icon='fas fa-external-link-alt'
            onClick={this.onPreview}
            style={{ position: 'absolute', top: 4, right: disabled ? 38 : 72 }}
          />
        )}
        {model.values[name] && (
          <RoundIcon
            tooltip='Herunterladen'
            color='white'
            icon='fa fa-download'
            onClick={this.onDownload}
            style={{ position: 'absolute', top: 4, right: disabled ? 4 : 38 }}
          />
        )}
        {model.values[name] && !disabled && (
          <RoundIcon
            tooltip='Löschen'
            color='white'
            icon='fa fa-trash'
            onClick={this.onDelete}
            style={{ position: 'absolute', top: 4, right: 4 }}
          />
        )}
        {this.upload && <UploadStripe width='100%' state={this.upload} />}
        {children}
      </div>
    )
  }
}
