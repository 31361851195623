import { Button } from 'components/Form/components/Button'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { IEmployeeCommActiveUser } from 'contracts/employeeComm/interfaces/IEmployeeCommActiveUser'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { DocumentDropzone } from 'modules/Documents/components/DocumentDropzone'
import { SalarySlipDropzoneView } from 'modules/Documents/components/SalarySlipDropzoneView'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { SalarySlipUploadRow } from './SalarySlipUploadRow'
import { SalarySlipErrorRow } from './SalarySlipErrorRow'
import { sleep } from 'helpers/sleep'
import { Spinner } from 'components/Spinner'
import { hermes } from '@byll/hermes'
import { dayjs } from 'helpers/dayjs'
import { SalarySlipMissingContractRow } from './SalarySlipMissingContractRow'

interface Props {
  company: InputSelectOption
  month: string
  employees: IEmployeeCommActiveUser[]
  onClose: (url?: string) => void
  topicId: string
}

const fileRx = /^lobn_(?:[0-9]+)_(?:[0-9]+)_(?:[0-9]+)_([0-9]+)(?:_[0-9]+)?\.pdf$/

@observer
export class NewSalarySlipUpload extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private hasUploadableRows = false
  @observable private missingContracts: (IDocumentMetadata & { file: Blob })[] = []
  @observable private upload: (IDocumentMetadata & { file: Blob })[] = []
  @observable private error: (IDocumentMetadata & { file: Blob })[] = []
  @observable private loading = false
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private onUpload = (doc: IDocumentMetadata & { file: Blob }) => {
    const match = doc.name.toLowerCase().match(fileRx)
    doc.userId = null
    if (match) {
      const pn = +match[1]
      const found = this.props.employees.filter((employee) => {
        const employeePn = +(employee.personnelNumber || '')
        return employeePn && employeePn === pn
      })
      if (found.length > 0) {
        doc.userId = found[0]?.id || null
        doc.uploadUser = `${found[0].firstName} ${found[0].lastName}` // This field is used for the assigned employee
      } else {
        doc.uploadUser = ''
      }
      if (found.length === 1) {
        // Only set contract if the mapping is unique (only one possible contract available)
        doc.category = `${this.props.topicId}/${found[0].contractId}`
        doc.notes = `${found[0].contractType} ab ${dayjs(found[0].joinDate).format(
          'DD.MM.YYYY',
        )}`
      }
    }
    if (doc.userId && doc.category) {
      this.upload.push(doc)
    } else if (doc.userId) {
      this.missingContracts.push(doc)
    } else {
      this.error.push(doc)
    }
  }

  @action
  private onSelectContract = () => {
    this.hasUploadableRows = this.missingContracts.filter((c) => !!c.category).length > 0
  }

  private onFinish = async () => {
    for (let i = this.missingContracts.length - 1; i >= 0; i--) {
      if (this.missingContracts[i].category) {
        this.upload.unshift(this.missingContracts[i])
        this.missingContracts.splice(i, 1)
      }
    }
    while (true) {
      // Check if it's still uploading
      if (this.upload.filter((d) => d.validTillDate !== 'uploaded').length === 0) {
        break
      }
      await sleep(100)
    }
    runInAction(() => (this.loading = true))
    hermes.create(`/api/${this.context.instance.id}/notify`, {
      path: `/api/${this.context.instance.id}/employeeComm/topics/${this.props.topicId}/threads`,
    })
    await sleep(500)
    this.props.onClose(`/employee/communication/${this.props.topicId}`)
  }

  render() {
    if (this.loading) {
      return (
        <div className='h-[200px]'>
          <Spinner />
        </div>
      )
    }

    return (
      <div className='min-h-[200px] pt-4'>
        {this.missingContracts.length > 0 &&
          this.missingContracts.map((doc) => (
            <SalarySlipMissingContractRow
              doc={doc}
              key={doc.id}
              employees={this.props.employees}
              topicId={this.props.topicId}
              onSelectContract={this.onSelectContract}
            />
          ))}
        {this.upload.length > 0 &&
          this.upload.map((doc) => <SalarySlipUploadRow doc={doc} key={doc.id} />)}
        {this.error.length > 0 &&
          this.error.map((doc) => <SalarySlipErrorRow doc={doc} key={doc.id} />)}
        {this.upload.length === 0 && this.error.length === 0 && (
          <DocumentDropzone
            scope='salary slip'
            onSelect={this.onUpload}
            view={SalarySlipDropzoneView}
            accept='application/pdf,.pdf'
            multiple
            maxMb={2}
          />
        )}

        <div className='text-right py-4 mt-5 sticky bottom-0 bg-white border-t border-gray-300 -mx-6 px-6 -mb-4'>
          <Button
            onClick={() => this.props.onClose()}
            color='secondary'
            outline
            className='mr-3'
          >
            Abbrechen
          </Button>
          <Button
            color='primary'
            disabled={this.upload.length === 0 && !this.hasUploadableRows}
            onClick={this.onFinish}
          >
            Fertigstellen
          </Button>
        </div>
      </div>
    )
  }
}
