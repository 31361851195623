import { Resource, hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Callout } from 'components/Callout'
import { Spinner } from 'components/Spinner'
import {
  IEmployeeCommThread,
  IEmployeeCommThreadSummarizedSalaryReportExtra,
} from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import { observer } from 'mobx-react'
import { PdfPreview } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/components/PdfPreview'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { EmployeeSalarySheetContact } from './components/EmployeeSalarySheetContact'
import { makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { EmployeeSalarySheetSendButton } from './components/EmployeeSalarySheetSendButton'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'
import { EmployeeCommPreviewBackButton } from 'modules/EmployeeCommunication/components/EmployeeCommPreviewBackButton'

interface Props {
  topic: IEmployeeCommTopic
  threadId: string
}

const statusColors: any = {
  yes: 'bg-green-500',
  no: 'bg-red-500',
  'manual yes': 'bg-yellow-500',
}

@observer
export class EmployeeSalaryReportPreview extends React.Component<Props, {}> {
  static contextType = AppContext
  private thread: Resource<IEmployeeCommThread>
  private readonly disposers: Disposer[] = []
  @observable private validityOverwriteWhileLoading = new Map<string, string>()

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.thread = new Resource(
      `/api/${context.instance.id}/employeeComm/topics/${props.topic.id}/threads/${props.threadId}`,
    )
    makeObservable(this)
  }

  componentDidMount(): void {
    this.disposers.push(this.thread.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private toggleContentCheck = async (
    check: IEmployeeCommThreadSummarizedSalaryReportExtra['content']['checks'][0],
  ) => {
    if (check.valid === 'yes') {
      return
    }
    if (this.context.permissions.employee_salarySheets < 2) {
      alert('Sie haben nicht die nötige Berechtigung, um diese Prüfung zu ändern.')
      return
    }
    const valid = check.valid === 'no' ? 'manual yes' : 'no'
    runInAction(() => this.validityOverwriteWhileLoading.set(check.label, valid))
    try {
      await hermes.patch(
        `/api/${this.context.instance.id}/employeeComm/topics/${this.props.topic.id}/threads/${this.props.threadId}`,
        { check: { label: check.label, valid: valid } },
      )
    } catch (_e) {
      toast.error('Die Änderung konnte nicht vorgenommen werden.')
    }
    runInAction(() => this.validityOverwriteWhileLoading.delete(check.label))
  }

  render() {
    if (this.thread.error?.id === NotFoundError.id) {
      return (
        <div className='h-[100%] relative'>
          <div className='absolute top-6 bottom-6 right-6 left-0 border-2 border-gray-300 border-dashed rounded-md flex flex-col'>
            <Callout
              title='Lohnsteuerbescheinigung nicht gefunden'
              icon='far fa-lemon text-yellow-400'
            />
          </div>
        </div>
      )
    }

    if (!this.thread.data) {
      return <Spinner delay />
    }

    const extra: IEmployeeCommThreadSummarizedSalaryReportExtra = this.thread.data.extra
    return (
      <div className='absolute top-6 bottom-6 right-6 overflow-x-hidden overflow-y-auto'>
        <div className='bg-white flex flex-wrap gap-2 p-4 truncate max-w-[840px]'>
          <EmployeeCommPreviewBackButton
            url={`/employee/communication/${this.props.topic.id}`}
          />
          {extra.content.checks.map((check, index) => (
            <span
              key={index}
              onClick={() => this.toggleContentCheck(check)}
              className={`${
                check.valid === 'yes' ? '' : 'cursor-pointer'
              } inline-flex whitespace-nowrap items-center rounded-md px-2 py-1 text-xs font-medium text-white ${
                statusColors[
                  this.validityOverwriteWhileLoading.get(check.label) || check.valid
                ]
              }`}
            >
              {this.validityOverwriteWhileLoading.has(check.label) && (
                <span className='mr-1'>
                  <i className='fas fa-spinner fa-spin' />
                </span>
              )}
              {check.label}
            </span>
          ))}
          <EmployeeSalarySheetContact thread={this.thread.data} />

          {extra.document === 'not available' && (
            <span
              className={`inline-flex whitespace-nowrap items-center rounded-md px-2 py-1 text-xs font-medium text-white bg-red-500`}
            >
              PDF fehlt
            </span>
          )}

          {extra.document !== 'not available' && (
            <EmployeeSalarySheetSendButton thread={this.thread.data} />
          )}
        </div>
        <PdfPreview
          url={`/api/${this.context.instance.id}/documents/files/${this.thread.data.attachment?.id}`}
        />
      </div>
    )
  }
}
