import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../../../../../../../../../../components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { IInternalResidenceFormModel } from '../interfaces/IInternalResidenceFormModel'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputText } from 'components/Form/components/InputText'
import { InputDate } from 'components/Form/components/InputDate'
import { Age } from 'components/Age'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { ABRECHNUNGSMODUS_AWUM } from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import { InputDocument } from 'components/Form/components/InputDocument'
import { hermes } from '@byll/hermes'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { dayjs } from 'helpers/dayjs'
import { getNextBillingStatus } from '../helpers/getNextBillingStatus'
import { Message } from 'components/Message'
import { getZimmerkategorieOptions } from 'contracts/accommodations/helpers/getZimmerkategorieOptions'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'

export const foodOptions: InputSelectOption[] = [
  { value: '', label: 'Wählen...' },
  { value: 'Vollverpflegung', label: 'Vollverpflegung' },
  { value: 'Selbstverpflegung', label: 'Selbstverpflegung' },
]

export const babyOptions: InputSelectOption[] = [
  { value: false, label: 'Nein' },
  { value: true, label: 'Ja' },
]

export const billingStatusOptions: InputSelectOption[] = [
  { value: '', label: 'Keine Gebührenpflicht' },
  { value: 'Statuswechsler', label: 'Statuswechsler' },
  { value: 'Statuswechsler (volljährig)', label: 'Statuswechsler (volljährig)' },
  { value: 'Selbstzahler', label: 'Selbstzahler' },
  { value: 'Selbstzahler (volljährig)', label: 'Selbstzahler (volljährig)' },
  { value: 'Aufnahmeverfügung fehlt', label: 'Aufnahmeverfügung fehlt' },
]

interface Props {
  resident: IResident
  model: Model<IInternalResidenceFormModel>
  setStep: (step: 'done') => void
  onClose: () => void
  color: 'secondary' | 'warning'
}

@observer
export class InternalResidenceFormStep3 extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly occupancy: IOccupancy
  private readonly zimmerkategorieOptions: InputSelectOption[]
  private readonly disposers: Disposer[] = []
  @observable warning: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.occupancy = {
      id: props.model.values.occupancy?.id || '',
      capacity: props.model.values.occupancy?.capacity || '1',
      occupied: props.model.values.occupancy?.occupied || '0',
      beds: [...(props.model.values.occupancy?.beds ?? [])],
    }

    let [free]: any[] = this.occupancy.beds.filter(
      (b) => b.type === 'empty-bed' && b.lock === null,
    )
    if (!free) {
      const bed = this.occupancy.beds.length + 1
      this.occupancy.beds.push({
        id: '',
        type: 'empty-bed',
        bed,
        lock: null,
        beginAt: '',
        endAt: '',
        comment: '',
        resident: null,
      })
      free = { bed }
    }
    this.zimmerkategorieOptions = [
      { value: '', label: '' },
      ...getZimmerkategorieOptions(context.instance.id).map((value) => ({
        value,
        label: value,
      })),
    ]
    runInAction(() => (this.props.model.values.bed = free.bed))
    makeObservable(this)
  }

  componentDidMount(): void {
    if (this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_AWUM) {
      void this.fillAwumFields()
      this.disposers.push(
        reaction(
          () => this.props.model.values.documentId,
          (documentId) => {
            if (this.props.model.values.billingStatus === 'Aufnahmeverfügung fehlt') {
              this.props.model.values.billingAvDate = documentId
                ? dayjs().format('YYYY-MM-DD')
                : null
            } else {
              this.props.model.values.billingDateOfNoticeCreation = documentId
                ? dayjs().format('YYYY-MM-DD')
                : null
            }
          },
        ),
      )
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private fillAwumFields = async () => {
    // Load Zimmerkategorie
    const [nextRoom, resident] = await Promise.all([
      hermes.getOnceNew<IRoom>(
        `/api/${this.context.instance.id}/accommodations/rooms/${this.props.model.values.roomId}`,
        { getFromStoreIfPossible: true },
      ),
      hermes.getOnceNew<IResidentSearchResult>(
        `/api/${this.context.instance.id}/residentSearchResults/${this.props.resident.id}?fields=bookings`,
        { getFromStoreIfPossible: true },
      ),
    ])
    const beginAt = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    )
    const status = await getNextBillingStatus(
      beginAt,
      this.context.instance.id,
      nextRoom,
      resident?.data.bookings,
    )
    runInAction(() => {
      this.props.model.values.billingStatus = status.status
      this.props.model.values.billingCategory = status.category
      this.props.model.values.billingDateOfNoticeCreation = status.dateOfNoticeCreation
      this.props.model.values.billingAvDate = status.avDate
      this.warning = status.warning
    })
  }

  @action
  private onSelect = (bed) => (this.props.model.values.bed = bed)

  private onSubmit = () => {
    if (!this.props.model.isValid()) {
      this.props.model.setFocusToLeftTopmostInvalidField()
      return
    }
    this.props.setStep('done')
  }

  render() {
    return (
      <div>
        {this.warning && (
          <Message color='danger' className='mt-4 text-red-500 font-bold'>
            {this.warning}
          </Message>
        )}
        <div className='relative grid grid-cols-4 gap-4 mt-4' id={this.props.model.id}>
          <InputDate
            disabled
            model={this.props.model}
            name='beginDate'
            label='Ab'
            placeholder='DD.MM.JJJJ'
          />
          <InputText
            disabled
            model={this.props.model}
            name='beginTime'
            tooltip='Uhrzeit benötigt'
            placeholder='hh:mm'
          />
          <div className='relative'>
            <InputDate
              disabled
              model={this.props.model}
              name='endDate'
              label='      Bis'
              placeholder='DD.MM.JJJJ'
            />
            <input
              type='checkbox'
              disabled
              checked={this.props.model.values.hasEnd}
              style={{
                position: 'absolute',
                top: -8,
                left: 12,
                zIndex: 1,
                transform: 'scale(0.9)',
                borderRadius: '4px',
              }}
              className='border-gray-300 text-indigo-600'
            />
          </div>
          <InputText
            disabled
            tooltip='Uhrzeit benötigt'
            model={this.props.model}
            name='endTime'
            placeholder='hh:mm'
          />

          {!!this.props.model.values.label && (
            <>
              <InputText
                disabled
                model={this.props.model}
                name='label'
                label='Raum'
                className={this.props.model.values.bed ? 'col-span-3' : 'col-span-4'}
              />
              {!!this.props.model.values.bed && (
                <InputText disabled model={this.props.model} name='bed' label='Bett' />
              )}
            </>
          )}

          {this.context.permissions.host_lfgb && (
            <>
              <InputSelect
                model={this.props.model}
                name='food'
                label='Verpflegung'
                className='col-span-3'
                options={foodOptions}
              />
              <InputSelect
                model={this.props.model}
                name='isBaby'
                label='Babybett'
                options={babyOptions}
              />
            </>
          )}

          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_AWUM && (
            <>
              {this.context.permissions.booking_feeStatus > 0 && (
                <InputSelect
                  disabled={this.context.permissions.booking_feeStatus < 2}
                  model={this.props.model}
                  name='billingStatus'
                  label='Gebührenstatus'
                  className='col-span-2'
                  options={billingStatusOptions}
                />
              )}
              {this.context.permissions.booking_feeCategory > 0 && (
                <InputSelect
                  disabled={this.context.permissions.booking_feeCategory < 2}
                  model={this.props.model}
                  name='billingCategory'
                  label='Zimmerkategorie'
                  options={this.zimmerkategorieOptions}
                  className='col-span-2'
                />
              )}
              {this.context.permissions.booking_feeNotice > 0 && (
                <InputDocument
                  disabled={this.context.permissions.booking_feeNotice < 2}
                  scope='booking'
                  model={this.props.model}
                  name='documentId'
                  label={
                    this.props.model.values.billingStatus === 'Aufnahmeverfügung fehlt'
                      ? 'Aufnahmeverfügung'
                      : 'Gebührenbescheid'
                  }
                  className='col-span-2'
                  preview
                />
              )}
              {this.context.permissions.booking_feeDateOfNoticeCreation > 0 &&
                this.props.model.values.billingStatus !== 'Aufnahmeverfügung fehlt' && (
                  <InputDate
                    disabled={
                      this.context.permissions.booking_feeDateOfNoticeCreation < 2
                    }
                    model={this.props.model}
                    name='billingDateOfNoticeCreation'
                    label='Erstellt am'
                    placeholder='DD.MM.JJJJ'
                    className='col-span-2'
                  />
                )}
              {this.context.permissions.booking_feeDateOfNoticeCreation > 0 &&
                this.props.model.values.billingStatus === 'Aufnahmeverfügung fehlt' && (
                  <InputDate
                    disabled={
                      this.context.permissions.booking_feeDateOfNoticeCreation < 2
                    }
                    model={this.props.model}
                    name='billingAvDate'
                    label='AV Datum'
                    placeholder='DD.MM.JJJJ'
                    className='col-span-2'
                  />
                )}

              {/* This is just a placeholder if there is an uneven number of fields visible */}
              {((this.context.permissions.booking_feeStatus > 0 ? 1 : 0) +
                (this.context.permissions.booking_feeCategory > 0 ? 1 : 0) +
                (this.context.permissions.booking_feeNotice > 0 ? 1 : 0) +
                (this.context.permissions.booking_feeDateOfNoticeCreation > 0 ? 1 : 0)) %
                2 ===
                1 && (
                <InputText
                  disabled
                  model={this.props.model}
                  name='placeholder'
                  label=''
                  className='col-span-2'
                />
              )}
            </>
          )}

          <div className='col-span-4 text-lg mt-4 -mb-1'>Bett wählen</div>

          {this.occupancy.beds.map((b) => (
            <div
              onClick={() =>
                b.type === 'empty-bed' && b.lock === null && this.onSelect(b.bed)
              }
              key={b.id}
              className={`col-span-4 truncate block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 ${
                this.props.model.values.bed === b.bed
                  ? 'border-blue-500 border-2'
                  : 'border-gray-300 border'
              } rounded-md bg-gray-100 ${
                b.type === 'empty-bed' && b.lock === null
                  ? 'cursor-pointer hover:bg-indigo-100'
                  : ''
              } p-2`}
            >
              {b.bed && (
                <span
                  className={`mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    b.bed > +this.occupancy.capacity || b.lock !== null
                      ? 'bg-red-500'
                      : 'bg-gray-500'
                  } text-white`}
                >
                  {b.lock !== null && (
                    <span className='mr-1'>
                      <i className='fas fa-lock' />
                    </span>
                  )}
                  {b.bed}
                </span>
              )}
              {b.resident?.isBaby && (
                <span className='mr-2 text-gray-500'>
                  <i className='fas fa-baby-carriage' />
                </span>
              )}
              {b.type === 'internal-reservation' && (
                <span className='mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border border-indigo-500 text-indigo-500'>
                  Reserviert
                </span>
              )}
              {b.type === 'internal-reservation' && !b.resident && (
                <span className='text-gray-400'>·&nbsp;&nbsp;{b.comment}</span>
              )}
              {b.resident && (
                <span>
                  <Age dateOfBirth={b.resident.dateOfBirth} sex={b.resident.sex as any} />
                  <span className='text-gray-900 mx-2'>{b.resident.name}</span>
                  {b.resident.nationality && (
                    <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-400 text-white'>
                      {b.resident.nationality}
                    </span>
                  )}
                </span>
              )}
              {b.type === 'empty-bed' && b.lock && (
                <span className='text-gray-400'>
                  {b.lock.reason}
                  {b.lock.notes && <>&nbsp;&nbsp;·&nbsp;&nbsp;{b.lock.notes}</>}
                </span>
              )}
              {b.type === 'empty-bed' && !b.lock && (
                <span className='text-gray-400'>Leeres Bett</span>
              )}
            </div>
          ))}
        </div>

        <div className='mt-4 flex text-right py-4 px-6 -mx-6 sticky bottom-0 bg-white -mb-4 border-t border-gray-200'>
          <div className='flex-auto flex'>
            {this.context.permissions.resident_booking_showRast && (
              <InputCheckbox model={this.props.model} name='hasRast' label='RAST Fall' />
            )}
          </div>
          <Button color='secondary' outline onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button color={this.props.color} className='ml-2' onClick={this.onSubmit}>
            Weiter
          </Button>
        </div>
      </div>
    )
  }
}
