import * as React from 'react'

import styles from '../styles.module.scss'

export class LogValue extends React.Component<
  { value: string | number | boolean | null | undefined },
  {}
> {
  render() {
    if (typeof this.props.value === 'string') {
      if (!this.props.value) {
        return <i className={styles.empty}>leer</i>
      }
      const parts = this.props.value.split('|')
      if (parts.length === 2 && parts[1].startsWith('/')) {
        // Link
        return (
          <a
            href={parts[1]}
            rel='noreferrer'
            target='_blank'
            className='underline text-blue-500 hover:text-black'
          >
            {parts[0]}
          </a>
        )
      }
      return <span>{this.props.value}</span>
    }
    if (typeof this.props.value === 'boolean') {
      return this.props.value ? <span>Ja</span> : <span>Nein</span>
    }
    if (this.props.value === undefined || (this.props.value as any) === null) {
      return <i className={styles.empty}>leer</i>
    }
    throw new Error(
      `Value component cannot handle the value ${this.props.value} of type ${typeof this
        .props.value}`,
    )
  }
}
