import { Model } from 'components/Form/Model'
import { InputDropdownOption } from 'components/Form/components/InputDropdown'
import { InputMonth } from 'components/Form/components/InputMonth'
import { InputText } from 'components/Form/components/InputText'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { action, makeObservable, observable } from 'mobx'
import { IEmployeeCommActiveUser } from 'contracts/employeeComm/interfaces/IEmployeeCommActiveUser'
import { NewSalarySlipUpload } from './components/NewSalarySlipUpload'
import { NewSalarySlipEmployeeList } from './components/NewSalarySlipEmployeeList'

interface Props {
  company: InputDropdownOption
  onClose: (url?: string) => void
}

@observer
export class NewSalarySlipTopic extends React.Component<Props, {}> {
  @observable.ref private employees: IEmployeeCommActiveUser[] | null = null // null: list step, array: upload step
  @observable private topicId: string | null = null
  private readonly model: Model<{ month: string; company: string }>
  constructor(props: Props) {
    super(props)
    this.model = new Model({
      month: dayjs().format('YYYY-MM'),
      company: props.company.label,
    })
    makeObservable(this)
  }

  @action
  private setEmployees = (employees: IEmployeeCommActiveUser[], topicId: string) => {
    this.employees = employees
    this.topicId = topicId
  }

  render() {
    return (
      <>
        <div className='mt-2 flex gap-4'>
          <InputMonth
            className='flex-[1_1_100px]'
            model={this.model}
            name='month'
            disabled={!!this.employees}
          />
          <InputText
            className='flex-[1_1_100px]'
            model={this.model}
            name='company'
            label='Firma'
            disabled
          />
        </div>

        {!this.employees && (
          <NewSalarySlipEmployeeList
            setEmployees={this.setEmployees}
            onClose={this.props.onClose}
            company={this.props.company}
            month={this.model.values.month}
            key={this.props.company.value + '.' + this.model.values.month}
          />
        )}

        {this.employees && this.topicId && (
          <NewSalarySlipUpload
            topicId={this.topicId}
            employees={this.employees}
            company={this.props.company}
            month={this.model.values.month}
            onClose={this.props.onClose}
          />
        )}
      </>
    )
  }
}
