import { observer } from 'mobx-react'
import * as React from 'react'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { IEmployeeCommThread } from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { Button } from 'components/Form/components/Button'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { isValidSummarizedSalaryReport } from 'contracts/employeeComm/helpers/isValidSummarizedSalaryReport'

interface Props {
  salarySheets: IEmployeeCommThread[]
  onClose: () => void
}

@observer
export class SendAllValidSalarySheets extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private selected = new Set<string>()
  @observable private sent: number | null = null
  private mounted = true

  constructor(props: Props) {
    super(props)
    for (const salarySheet of props.salarySheets) {
      if (isValidSummarizedSalaryReport(salarySheet.extra)) {
        this.selected.add(salarySheet.id)
      }
    }
    makeObservable(this)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  @action
  private onToggle = (salarySheet: IEmployeeCommThread) => {
    if (this.selected.has(salarySheet.id)) {
      this.selected.delete(salarySheet.id)
    } else {
      this.selected.add(salarySheet.id)
    }
  }

  private onSubmit = async () => {
    runInAction(() => (this.sent = 0))
    for (const thread of this.props.salarySheets) {
      if (!this.selected.has(thread.id)) {
        continue
      }
      try {
        await hermes.create(
          `/api/${this.context.instance.id}/employeeComm/topics/${thread.topicId}/threads/${thread.id}/send`,
          {},
        )
        runInAction(() => (this.sent = (this.sent ?? 0) + 1))
      } catch (_e) {
        /* */
      }
      if (!this.mounted) {
        return
      }
    }
    this.props.onClose()
  }

  render() {
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='text-left'>
          <Dialog.Title
            as='h3'
            className='text-lg leading-6 font-medium text-gray-900 mb-4'
          >
            Lohnsteuerbescheinigungen versenden
          </Dialog.Title>
        </div>

        <div>
          {this.props.salarySheets.map((salarySheet) => {
            const valid = isValidSummarizedSalaryReport(salarySheet.extra)
            return (
              <div key={salarySheet.id} className='flex items-center gap-2'>
                <input
                  type='checkbox'
                  className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded ${
                    !valid ? 'bg-gray-100' : ''
                  }`}
                  checked={this.selected.has(salarySheet.id)}
                  disabled={!valid}
                  onChange={() => this.onToggle(salarySheet)}
                />
                <span>{`${salarySheet.employee.firstName} ${salarySheet.employee.lastName}`}</span>
                {!valid && (
                  <span className='inline-flex items-center rounded-md bg-red-500 px-2 py-0.5 text-xs font-medium text-white'>
                    Nicht versandfertig
                  </span>
                )}
                {valid && (
                  <span className='inline-flex items-center rounded-md bg-green-500 px-2 py-0.5 text-xs font-medium text-white'>
                    Gültig
                  </span>
                )}
              </div>
            )
          })}
        </div>

        <div className='text-right py-4 mt-5 sticky bottom-0 bg-white border-t border-gray-300 -mx-6 px-6 -mb-4'>
          <Button onClick={this.props.onClose} color='secondary' outline className='mr-3'>
            Abbrechen
          </Button>
          <Button
            onClick={this.onSubmit}
            color='primary'
            disabled={this.selected.size === 0 || this.sent !== null}
          >
            Versenden
          </Button>
        </div>

        {this.sent !== null && (
          <DialogOverlaySpinner opaque>
            <div className='text-center pt-9 text-gray-500 text-sm'>
              {`${this.sent} / ${this.selected.size}`}
              <br />
              <Button
                onClick={this.props.onClose}
                color='secondary'
                outline
                className='mt-2 py-1'
              >
                Abbrechen
              </Button>
            </div>
          </DialogOverlaySpinner>
        )}
      </div>
    )
  }
}
