import { Model } from 'components/Form/Model'
import { InputDropdownOption } from 'components/Form/components/InputDropdown'
import { InputText } from 'components/Form/components/InputText'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { action, makeObservable, observable } from 'mobx'
import { IEmployeeCommActiveUser } from 'contracts/employeeComm/interfaces/IEmployeeCommActiveUser'
import { InputYear } from 'components/Form/components/InputYear'
import { NewSalarySummaryReportEmployeeList } from './components/NewSalarySummaryReportEmployeeList'
import { NewSalarySummaryReportUpload } from './components/NewSalarySummaryReportUpload'

interface Props {
  company: InputDropdownOption
  onClose: (url?: string) => void
}

@observer
export class NewSalarySummaryReportTopic extends React.Component<Props, {}> {
  @observable.ref private employees: IEmployeeCommActiveUser[] | null = null // null: list step, array: upload step
  @observable private topicId: string | null = null
  private readonly model: Model<{
    year: number
    minYear: number
    maxYear: number
    company: string
  }>
  constructor(props: Props) {
    super(props)
    this.model = new Model({
      minYear: dayjs().subtract(4, 'year').year(),
      maxYear: dayjs().year(),
      year: dayjs().subtract(1, 'year').year(),
      company: props.company.label,
    })
    makeObservable(this)
  }

  @action
  private setEmployees = (employees: IEmployeeCommActiveUser[], topicId: string) => {
    this.employees = employees
    this.topicId = topicId
  }

  render() {
    return (
      <>
        <div className='mt-2 flex gap-4'>
          <InputYear
            className='flex-[1_1_100px]'
            model={this.model}
            name='year'
            minYear={this.model.values.minYear}
            maxYear={this.model.values.maxYear}
            disabled={!!this.employees}
          />
          <InputText
            className='flex-[1_1_100px]'
            model={this.model}
            name='company'
            label='Firma'
            disabled
          />
        </div>

        {!this.employees && (
          <NewSalarySummaryReportEmployeeList
            setEmployees={this.setEmployees}
            onClose={this.props.onClose}
            company={this.props.company}
            year={this.model.values.year}
            key={`${this.props.company.value}.${this.model.values.year}`}
          />
        )}

        {this.employees && this.topicId && (
          <NewSalarySummaryReportUpload
            topicId={this.topicId}
            employees={this.employees}
            company={this.props.company}
            year={this.model.values.year}
            onClose={this.props.onClose}
          />
        )}
      </>
    )
  }
}
