import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { getFileExtensionIcon } from 'modules/Residents/modules/CaseRecord/components/CaseRecordBody/components/OverviewTab/components/OverviewDocuments/helpers/getFileExtensionIcon'
import * as React from 'react'
import styles from '../styles.module.scss'
import { observer } from 'mobx-react'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { IEmployeeCommActiveUser } from 'contracts/employeeComm/interfaces/IEmployeeCommActiveUser'
import { dayjs } from 'helpers/dayjs'

interface Props {
  topicId: string
  doc: IDocumentMetadata & { file: Blob }
  employees: IEmployeeCommActiveUser[]
  onSelectContract: () => void
}

@observer
export class SalarySlipMissingContractRow extends React.Component<Props, {}> {
  private model: Model<{ contractId: string | null }>
  private options: InputSelectOption[] = [{ value: null, label: 'Bitte wählen...' }]
  constructor(props: Props) {
    super(props)
    this.model = new Model({ contractId: null })
    for (const employee of this.props.employees) {
      if (employee.id === this.props.doc.userId) {
        this.options.push({
          value: employee.contractId,
          label: `${employee.contractType} ab ${dayjs(employee.joinDate).format(
            'DD.MM.YYYY',
          )}`,
        })
      }
    }
  }

  private onSelect = () => {
    if (!this.model.values.contractId) {
      this.props.doc.category = ''
      this.props.doc.notes = ''
      this.props.onSelectContract()
      return
    }
    const [contract] = this.props.employees.filter(
      (e) => e.contractId === this.model.values.contractId,
    )
    if (!contract) {
      alert('Es ist ein Fehler aufgetreten.')
      return
    }
    this.props.doc.category = `${this.props.topicId}/${contract.contractId}`
    this.props.doc.notes = `${contract.contractType} ab ${dayjs(contract.joinDate).format(
      'DD.MM.YYYY',
    )}`
    this.props.onSelectContract()
  }

  render() {
    const doc = this.props.doc
    return (
      <div className='relative'>
        {/* Select file */}
        <div className='flex bg-white shadow-md h-[70px] cursor-pointer mt-4'>
          <div className='flex-[0_0_70px] bg-center'>
            <img
              src={getFileExtensionIcon(doc.name)}
              alt=''
              style={{ height: 50, margin: 10 }}
            />
          </div>
          <div className='flex-auto'>
            <div className='flex'>
              <div
                className={styles.documentName}
                style={{
                  maxWidth: 400,
                  color: this.model.values.contractId ? 'black' : 'red',
                }}
              >
                {doc.name}
              </div>
            </div>
            <div
              className={`text-gray-400 text-sm truncate ${styles.documentDetails} flex`}
            >
              <InputSelect
                onChange={this.onSelect}
                className='flex-[0_0_140px]'
                options={this.options}
                model={this.model}
                name='contractId'
                style={{ padding: '0 30px 0 4px', borderRadius: '0.375rem 0 0 0.375rem' }}
              />
              {this.model.values.contractId ? (
                <div
                  key='check'
                  className='bg-green-500 flex-[0_0_22px] text-white text-center'
                  style={{ borderRadius: '0 0.375rem 0.375rem 0' }}
                >
                  <i className='fas fa-check' />
                </div>
              ) : (
                <div
                  key='error'
                  className='bg-red-500 flex-[0_0_22px] text-white text-center'
                  style={{ borderRadius: '0 0.375rem 0.375rem 0' }}
                >
                  <i className='fas fa-exclamation-triangle' />
                </div>
              )}
              <div>
                &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
                <span
                  className={
                    this.model.values.contractId ? 'text-green-500' : 'text-red-500'
                  }
                >
                  {doc.uploadUser}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
