import { XIcon } from '@heroicons/react/outline'
import { observer } from 'mobx-react'
import { templates } from 'modules/Pdf/templates/ResidentDocumentTemplates/templates'
import * as React from 'react'
import { DocumentTemplateCheckbox } from './DocumentTemplateCheckbox'
import {
  DOCX_TEMPLATE_PREFIX,
  IDocumentTemplate,
} from 'contracts/general/interfaces/IDocumentTemplate'
import { Collection } from '@byll/hermes'
import { makeObservable, observable } from 'mobx'

interface Props {
  onClose: () => void
  templates: Collection<IDocumentTemplate>
}

@observer
export class SelectDocumentTemplatesDialog extends React.Component<Props, {}> {
  private allTemplates = templates
  @observable private readonly availableTemplates: Set<string>

  constructor(props: Props) {
    super(props)
    this.availableTemplates = new Set()
    for (const t of props.templates.resources || []) {
      if (!t.id.startsWith(DOCX_TEMPLATE_PREFIX)) {
        this.availableTemplates.add(t.id)
      }
    }
    makeObservable(this)
  }

  render() {
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div className='text-xl text-gray-600 mb-4'>Verfügbare Vorlagen</div>
        {Array.from(this.allTemplates.keys()).map((k) => {
          return (
            <DocumentTemplateCheckbox
              key={k}
              available={this.availableTemplates}
              currentKey={k}
            />
          )
        })}
      </div>
    )
  }
}
