import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { Callout } from 'components/Callout'
import { Spinner } from 'components/Spinner'
import { useCollection } from 'helpers/useCollection'
import { useResource } from 'helpers/useResource'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { SalarySlipContent } from './components/SalarySlipContent'
import { IEmployeeCommTopic } from 'contracts/employeeComm/interfaces/IEmployeeCommTopic'
import { IEmployeeCommThread } from 'contracts/employeeComm/interfaces/IEmployeeCommThread'
import { SummarizedSalaryReport } from './components/SummarizedSalaryReport'

interface Props {
  topicId: string
  threadId: string | null
  onSelect: (threadId: string | null) => void
}

export const EmployeeCommunicationContent: React.FC<Props> = observer((props) => {
  const context = React.useContext(AppContext)
  const topic = useResource<IEmployeeCommTopic>(
    `/api/${context.instance.id}/employeeComm/topics/${props.topicId}`,
    { readOnly: true },
  )
  const threads = useCollection<IEmployeeCommThread>(
    `/api/${context.instance.id}/employeeComm/topics/${props.topicId}/threads`,
    { readOnly: true },
  )

  if (
    topic.error?.id === RequestPendingError.id ||
    threads.error?.id === RequestPendingError.id
  ) {
    return (
      <div className='flex-auto relative'>
        <div className='absolute left-6 right-6 bottom-6 top-6 overflow-x-hidden overflow-y-auto flex'>
          <Spinner delay />
        </div>
      </div>
    )
  }

  if (topic.error || !topic.data) {
    return (
      <div className='flex-auto relative'>
        <div className='absolute left-6 right-6 bottom-6 top-6 overflow-x-hidden overflow-y-auto flex'>
          <Callout
            title='Ungültige URL oder Berechtigung'
            subtitle='Wählen Sie eine Option aus der Seitenleiste.'
            icon='fas fa-exclamation-triangle'
          />
        </div>
      </div>
    )
  }

  if (topic.data.type === 'salary slip') {
    return (
      <SalarySlipContent
        topic={topic.data}
        threads={threads}
        onSelect={props.onSelect}
        threadId={props.threadId}
      />
    )
  }

  if (topic.data.type === 'summarized salary report') {
    return (
      <SummarizedSalaryReport
        topic={topic.data}
        threads={threads}
        onSelect={props.onSelect}
        threadId={props.threadId}
      />
    )
  }

  return (
    <div className='flex-auto relative'>
      <div className='absolute left-6 right-6 bottom-6 top-6 overflow-x-hidden overflow-y-auto flex'>
        <Callout title='Unsupported Topic' icon='fas fa-exclamation-triangle' />
      </div>
    </div>
  )
})
