import { Collection, hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IDocumentFolder } from 'contracts/general/interfaces/IDocumentFolder'
import { IDocumentMetadata } from 'contracts/general/interfaces/IDocumentMetadata'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { RoundIcon } from 'components/RoundIcon'
import { Dialog } from 'components/Dialog'
import { DocumentDialog } from './components/DocumentDialog'
import { makeObservable, observable, reaction } from 'mobx'
import { Spinner } from 'components/Spinner'
import { getFileExtensionIcon } from './helpers/getFileExtensionIcon'
import folderIcon from './images/folder.png'
import { dayjs } from 'helpers/dayjs'
import { box } from 'services/box'
import { NewDocumentDropdown } from './components/NewDocumentDropdown'
import { DocumentTemplateDialog } from './components/DocumentTemplateDialog'
import { IDocumentTemplate } from 'contracts/general/interfaces/IDocumentTemplate'
import { Route, Routes } from 'react-router'
import { FolderPermissionsDialog } from './components/FolderPermissionsDialog'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { getCurrentResponsibilityCompoundId } from 'contracts/residents/helpers/getCurrentResponsibilityCompoundId'
import { isStammCompound } from 'helpers/isStamm'
import { SelectDocumentTemplatesDialog } from './components/SelectDocumentTemplatesDialog'
import { MoveDocumentDialog } from './components/MoveDocumentDialog'
import { TemplateManagementDialog } from './components/TemplateManagementDialog'

interface Props {
  resident: IResident
  navigate: (url: string) => void
}

@observer
export class OverviewDocuments extends React.Component<
  Props,
  {
    selectedFolder: IDocumentFolder | null
    selectedDocument: (IDocumentMetadata & { hasUnsavedChanges?: boolean }) | null
  }
> {
  static contextType = AppContext
  @observable private hasReponsibilityInStammcompound = false
  private readonly searchResult: Resource<IResidentSearchResult>
  private readonly folders: Collection<IDocumentFolder>
  private readonly documents: Collection<IDocumentMetadata>
  private readonly templates: Collection<IDocumentTemplate>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.state = { selectedFolder: null, selectedDocument: null }
    this.searchResult = new Resource(
      `/api/${context.instance.id}/residentSearchResults/${props.resident.id}`,
    )
    this.folders = new Collection(`/api/${context.instance.id}/documents/folders`, {
      scope: 'resident',
    })
    this.documents = new Collection(`/api/${context.instance.id}/documents/metadata`, {
      scope: 'resident',
      residentId: props.resident.id,
      familyId: props.resident.familyId,
    })
    this.templates = new Collection(`/api/${context.instance.id}/documents/templates`)
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.searchResult.init({ readOnly: true }))
    this.disposers.push(this.folders.init({ readOnly: true }))
    this.disposers.push(this.documents.init({ readOnly: true }))
    this.disposers.push(this.templates.init({ readOnly: true }))
    this.disposers.push(
      reaction(
        () =>
          isStammCompound(
            getCurrentResponsibilityCompoundId(this.searchResult.data?.data.bookings) ||
              '',
          ),
        (has) => (this.hasReponsibilityInStammcompound = has),
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private folderMapper = (res: Resource<IDocumentFolder>) => {
    const folder = res.data
    if (!folder || !this.documents.resources) {
      return null
    }
    const selected = this.state.selectedFolder?.id === folder.id
    const documents = this.documents.resources.filter(
      (d) => d.data?.folderId === folder.id,
    )
    const hasCreatePermission =
      folder.visible &&
      (folder.uploadDocuments === 2 ||
        (folder.uploadDocuments === 1 && this.hasReponsibilityInStammcompound))
    const hasDeletePermission =
      folder.visible &&
      (folder.deleteDocuments === 2 ||
        (folder.deleteDocuments === 1 && this.hasReponsibilityInStammcompound))
    const hasDownloadPermission =
      folder.visible &&
      (folder.downloadDocuments === 2 ||
        (folder.downloadDocuments === 1 && this.hasReponsibilityInStammcompound))
    return (
      <div
        key={folder.id}
        className={`${
          selected
            ? 'border-2 border-indigo-500 bg-gray-100 p-3'
            : 'hover:bg-indigo-100 bg-gray-100'
        } mt-4 rounded-md overflow-hidden transition-height duration-500 ease-in-out relative`}
        style={{
          height: !selected
            ? 40
            : (Math.max(1, folder.visible ? documents.length : 0) + 1) * 56 + 16,
        }}
      >
        <div
          className='flex cursor-pointer'
          onClick={() =>
            this.setState({
              selectedFolder: this.state.selectedFolder?.id === folder.id ? null : folder,
            })
          }
        >
          <div className='flex-content'>
            <img src={folderIcon} style={{ height: 40 }} alt='Ordner' />
          </div>
          <div className='flex-auto px-3 py-2 truncate'>
            <span>{folder.label}</span>
            {documents.length > 0 && !selected && folder.visible && (
              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 border border-indigo-500 ml-2'>
                {documents.length}
              </span>
            )}
            {!folder.visible && (
              <span className='text-red-500 ml-2'>
                <i className='fas fa-lock' />
              </span>
            )}
            {selected && hasCreatePermission && (
              <NewDocumentDropdown onSelect={this.openDialog} />
            )}
            {selected && !hasCreatePermission && (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  void box.alert(
                    'Keine Berechtigung',
                    'Sie haben leider nicht die nötige Berechtigung, um Dokumente in diesem Ordner anzulegen.',
                  )
                }}
                className='bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-indigo-500 text-center rounded-full shadow-md text-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 absolute'
                style={{ width: 30, height: 30, padding: 0, top: 17, right: 17 }}
              >
                <i className='fas fa-plus' aria-hidden='false' />
              </button>
            )}
          </div>
          {!selected && this.context.permissions.resident_changeDocumentPermissions && (
            <div
              className='flex-content mr-3 text-gray-500 hover:text-blue-500'
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                this.props.navigate(
                  `/residents/${toJbpId(
                    +this.props.resident.id,
                  ).toLowerCase()}/overview/documents/permissions/${folder.id}`,
                )
              }}
              style={{ lineHeight: '42px' }}
            >
              <i className='fas fa-cog' aria-hidden='false' />
            </div>
          )}
        </div>
        {folder.visible && (
          <div>
            {documents.map((d) =>
              this.documentMapper(d, hasDeletePermission, hasDownloadPermission),
            )}
            {documents.length === 0 && (
              <div className='mt-4 text-gray-500 px-3 py-2 rounded-md truncate'>
                <i className='fas fa-info-circle' /> Dieser Ordner enthält noch keine
                Dokumente
              </div>
            )}
          </div>
        )}
        {!folder.visible && (
          <div className='mt-4 bg-red-500 text-white px-3 py-2 rounded-md truncate'>
            <i className='fas fa-lock' />
            &nbsp;&nbsp;Sie haben keine Zugriffsberechtigung für diesen Ordner
          </div>
        )}
      </div>
    )
  }

  private setSelectedDocument = (
    doc: IDocumentMetadata & { hasUnsavedChanges?: boolean },
  ) => {
    if (!this.templates.resources) {
      box.alert(
        'Dokumente',
        'Leider konnten notwendige Metadaten nicht geladen werden. Bitte versuchen Sie es später erneut oder wenden sich an einen Administrator.',
      )
      return
    }
    this.setState({ selectedDocument: doc })
  }

  private moveDocument = async (document: IDocumentMetadata) => {
    const promise = box.custom(
      <MoveDocumentDialog
        hasReponsibilityInStammcompound={this.hasReponsibilityInStammcompound}
        document={document}
        folders={this.folders}
        onClose={() => promise.close()}
      />,
      { context: this.context },
    )
  }

  private documentMapper = (
    document: Resource<IDocumentMetadata>,
    hasDeletePermission: boolean,
    hasDownloadPermission: boolean,
  ) => {
    if (!document.data) {
      return null
    }
    return (
      <div
        key={document.id}
        className='group mt-4 hover:bg-indigo-100 flex rounded-md cursor-pointer truncate relative'
        onClick={() => {
          hasDownloadPermission
            ? this.setSelectedDocument(document.data!)
            : void box.alert(
                'Keine Berechtigung',
                'Sie haben nicht die nötige Berechtigung, um dieses Dokument anzusehen.',
              )
        }}
      >
        <div style={{ flex: '0 0 40px' }} className='ml-4'>
          <img
            src={getFileExtensionIcon(document.data.name)}
            alt='Datei'
            style={{ height: 40 }}
          />
        </div>
        <div className='flex-auto px-3 py-2 '>
          {document.data.familyId && (
            <span className='mr-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800 border border-indigo-500'>
              Familie
            </span>
          )}
          {document.data.name}
          <span className='text-gray-400 text-sm'>
            &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
            {dayjs(document.data.createdAt).format('DD.MM.YYYY')}
          </span>
          {document.data.qrCodeId && (
            <span className='text-gray-400 text-sm'>
              &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
              <i className='fa fa-qrcode' />
            </span>
          )}
        </div>
        <RoundIcon
          classNameContainer='hidden group-hover:block'
          tooltip={{ text: 'In neuem Tab öffnen', position: 'left' }}
          style={{
            position: 'absolute',
            top: 5,
            right: this.context.permissions.resident_documents_move ? 75 : 40,
          }}
          icon='fas fa-external-link-alt'
          color='white'
          onClick={(event) => {
            event.stopPropagation()
            hasDownloadPermission
              ? this.openDocumentInNewTab(document.id)
              : void box.alert(
                  'Keine Berechtigung',
                  'Sie haben nicht die nötige Berechtigung, um dieses Dokument anzusehen.',
                )
          }}
        />
        {this.context.permissions.resident_documents_move && (
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Dokument verschieben', position: 'left' }}
            style={{ position: 'absolute', top: 5, right: 40 }}
            icon='fas fa-folder-open'
            color='warning'
            onClick={(event) => {
              event.stopPropagation()
              this.moveDocument(document.data!)
            }}
          />
        )}
        <RoundIcon
          classNameContainer='hidden group-hover:block'
          tooltip={{ text: 'Dokument löschen', position: 'left' }}
          style={{ position: 'absolute', top: 5, right: 5 }}
          icon='fas fa-trash'
          color='danger'
          onClick={(event) => {
            event.stopPropagation()
            this.deleteDocument(document.id, hasDeletePermission)
          }}
        />
      </div>
    )
  }

  private deleteDocument = async (id: string, hasDeletePermission: boolean) => {
    if (!hasDeletePermission) {
      void box.alert(
        'Keine Berechtigung',
        'Sie haben leider nicht die nötige Berechtigung, um Dokumente in diesem Ordner zu löschen.',
      )
      return
    }

    const confirmed = await box.alert(
      'Dokument löschen',
      'Möchten Sie dieses Dokument wirklich löschen?',
      { confirm: 'Ja, jetzt löschen', cancel: 'Abbrechen', color: 'danger' },
    )
    if (!confirmed) {
      return
    }
    try {
      await hermes.delete(`/api/${this.context.instance.id}/documents/files/${id}`)
    } catch (_e) {
      box.alert(
        'Löschen fehlgeschlagen',
        'Das Dokument konnte nicht gelöscht werden. Bitte wenden Sie sich an einen Adminitrator.',
      )
    }
  }

  private openDocumentInNewTab = (id: string) => {
    window.open(`/documents/${id}`, '_blank')
  }

  // Only for new documents (uploads or created from template)
  private openDialog = (dialog: 'upload' | 'template') => {
    if (!this.state.selectedFolder) {
      return
    }
    const doc: IDocumentMetadata & { hasUnsavedChanges?: boolean } = {
      id: dialog === 'upload' ? '' : 'from template',
      folderId: this.state.selectedFolder.id,
      category: '',
      name: '',
      size: '0',
      userId: this.context.user.id,
      residentId: this.props.resident.id,
      familyId: null,
      documentationId: null,
      qrCodeId: null,
      notes: '',
      createdAt: new Date().toISOString(),
      uploadUser: `${this.context.user.firstName} ${this.context.user.lastName}`,
      hasUnsavedChanges: false,
      validTillDate: null,
    }
    this.setSelectedDocument(observable(doc))
  }

  private closeUploadDialog = () => {
    if (this.state.selectedDocument?.hasUnsavedChanges) {
      const result = window.confirm(
        'Sie haben ungespeicherte Änderungen. Wollen Sie den Dialog wirklich schließen und die Änderungen verwerfen?',
      )
      if (!result) {
        return
      }
    }
    this.setState({ selectedDocument: null })
  }

  private openTemplateEditDialog = () => {
    const promise = box.custom(
      <SelectDocumentTemplatesDialog
        templates={this.templates}
        onClose={() => promise.close()}
      />,
      { context: this.context },
    )
  }

  private openTemplateManagementDialog = () => {
    const promise = box.custom(
      <TemplateManagementDialog onClose={() => promise.close()} folders={this.folders} />,
      { context: this.context, size: 'md' },
    )
  }

  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div className='pr-12 pt-4 text-right' style={{ flex: '0 0 200px' }}>
          <span className='text-gray-900 text-lg'>Dokumente</span>
          <br />
          <span className='text-sm text-gray-400'>
            Unterlagen, die diesen Bewohner betreffen
          </span>
        </div>
        <div className='flex-auto pt-2 min-h-[180px] relative overflow-hidden'>
          {(!this.folders.resources || !this.documents.resources) && <Spinner delay />}
          {this.folders.resources?.map(this.folderMapper)}
          {this.context.permissions.resident_changeDocumentTemplates &&
            this.folders.resources && (
              <div
                className='hover:bg-indigo-100 bg-gray-100 mt-4 px-4 py-2 rounded-md overflow-hidden relative cursor-pointer'
                onClick={this.openTemplateManagementDialog}
              >
                <span className='text-lg'>
                  <i className='fas fa-edit text-gray-500 mr-2' />
                </span>
                Dokumentvorlagen bearbeiten
              </div>
            )}

          {/* Upload dialog */}
          <Dialog
            size='lg'
            open={
              this.state.selectedDocument !== null &&
              this.state.selectedDocument.id !== 'from template'
            }
            setOpen={this.closeUploadDialog}
          >
            {this.state.selectedDocument !== null &&
              this.state.selectedDocument.id !== 'from template' &&
              this.state.selectedFolder && (
                <DocumentDialog
                  onClose={this.closeUploadDialog}
                  folder={this.state.selectedFolder}
                  document={this.state.selectedDocument}
                  resident={this.props.resident}
                />
              )}
          </Dialog>

          {/* Upload dialog */}
          <Dialog
            size='lg'
            open={
              this.state.selectedDocument !== null &&
              this.state.selectedDocument.id === 'from template'
            }
            setOpen={this.closeUploadDialog}
          >
            {this.state.selectedDocument !== null &&
              this.state.selectedDocument.id === 'from template' &&
              this.state.selectedFolder &&
              !!this.templates.resources && (
                <DocumentTemplateDialog
                  onClose={this.closeUploadDialog}
                  folder={this.state.selectedFolder}
                  document={this.state.selectedDocument}
                  resident={this.props.resident}
                  templates={this.templates}
                />
              )}
          </Dialog>

          <Routes>
            <Route
              path='documents/permissions/:folderId'
              element={
                <FolderPermissionsDialog
                  resident={this.props.resident}
                  folders={this.folders}
                />
              }
            />
          </Routes>
        </div>
        {this.context.user.login === 'root' && (
          <span
            className='text-gray-500 cursor-pointer'
            onClick={this.openTemplateEditDialog}
          >
            <i className='fas fa-cog' />
          </span>
        )}
      </div>
    )
  }
}
