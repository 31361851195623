import { hermes } from '@byll/hermes'
import { Tooltip } from 'components/Tooltip'
import { Button } from 'components/Form/components/Button'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { IEmployeeCommActiveUser } from 'contracts/employeeComm/interfaces/IEmployeeCommActiveUser'
import { MONTHS } from 'contracts/general/helpers/months'
import { dayjs } from 'helpers/dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  company: InputSelectOption
  month: string
  onClose: () => void
  setEmployees: (employees: IEmployeeCommActiveUser[], topicId: string) => void
}

@observer
export class NewSalarySlipEmployeeList extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private employees: IEmployeeCommActiveUser[] | null = null
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.loadEmployees()
  }

  private loadEmployees = async () => {
    const employees = await hermes.indexOnceNew<IEmployeeCommActiveUser>(
      `/api/${this.context.instance.id}/employeeComm/activeEmployees?companyId=${this.props.company.value}&month=${this.props.month}`,
    )
    runInAction(() => (this.employees = employees))
  }

  private onSubmit = async () => {
    if (!this.employees || this.employees.length === 0) {
      return
    }
    const employees = this.employees
    runInAction(() => {
      this.employees = null
      this.error = null
    })
    try {
      const month = dayjs(this.props.month, 'YYYY-MM')
      const response = await hermes.create(
        `/api/${this.context.instance.id}/employeeComm/topics`,
        {
          type: 'salary slip',
          companyId: this.props.company.value,
          label: `Gehaltszettel ${MONTHS[month.month()]} ${month.format('YYYY')}`,
          params: { month: this.props.month },
        },
      )
      this.props.setEmployees(employees, response.id)
    } catch (_e) {
      runInAction(() => {
        this.employees = employees
        this.error = 'Fehler beim Anlegen des Sammeluploads.'
      })
    }
  }

  render() {
    if (!this.employees) {
      return (
        <div className='h-[200px]'>
          <Spinner delay />
        </div>
      )
    }

    if (this.error || this.employees.length === 0) {
      return (
        <div className='h-[200px]'>
          <Message color='danger' className='mt-4'>
            {this.error || 'Keine Mitarbeiter gefunden'}
          </Message>
        </div>
      )
    }

    return (
      <div className='min-h-[180px] pt-4'>
        <ul className='divide-y divide-gray-100'>
          {this.employees.map((person) => {
            const contractEnded = person.status === 'Karenzmonat'
            return (
              <li
                key={`${person.id}-${person.contractId}`}
                className='flex justify-between gap-x-6 py-5'
              >
                <div className='flex min-w-0 gap-x-4'>
                  <img
                    className='h-12 w-12 flex-none rounded-full bg-gray-50'
                    src={getUserImageSrc(
                      this.context.instance.id,
                      person.imageId,
                      person.sex,
                      'thumbnail',
                    )}
                    alt=''
                  />
                  <div className='min-w-0 flex-auto'>
                    <div className='text-sm truncate font-semibold leading-6 text-gray-900'>{`${person.title} ${person.firstName} ${person.lastName}`}</div>
                    <div className='mt-1 text-xs leading-5 text-gray-500'>
                      <span className='truncate max-w-[130px] mr-1 inline-flex'>
                        {person.contractType || '-'}
                      </span>
                      {contractEnded && (
                        <span className='relative items-center rounded-md bg-yellow-500 px-2 py-1 text-xs font-medium text-white has-tooltip'>
                          <Tooltip>
                            Dieser Vertrag wurde im Vormonat beendet.
                            <br />
                            Möglicherweise sind noch Zuschläge offen.
                          </Tooltip>
                          Karenzmonat
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className='hidden shrink-0 sm:flex sm:flex-col sm:items-end'>
                  <p className='text-sm truncate leading-6 text-gray-900 max-w-[150px]'>
                    {this.props.company.label}
                  </p>
                  <div className='mt-1 flex items-center gap-x-1.5'>
                    <div
                      className={`${
                        contractEnded ? 'bg-yellow-500/20' : 'bg-emerald-500/20'
                      } p-1 flex-none rounded-full`}
                    >
                      <div
                        className={`${
                          contractEnded ? 'bg-yellow-500' : 'bg-emerald-500'
                        } h-1.5 w-1.5 rounded-full`}
                      />
                    </div>
                    <p className='text-xs leading-5 text-gray-500'>
                      {`${dayjs(person.joinDate).format('DD.MM.YYYY')} - ${
                        person.resignDate
                          ? dayjs(person.resignDate).format('DD.MM.YYYY')
                          : 'unbefristet'
                      }`}
                    </p>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>

        <div className='text-right py-4 mt-5 sticky bottom-0 bg-white border-t border-gray-300 -mx-6 px-6 -mb-4'>
          <Button onClick={this.props.onClose} color='secondary' outline className='mr-3'>
            Abbrechen
          </Button>
          <Button onClick={this.onSubmit} color='primary'>
            Weiter
          </Button>
        </div>
      </div>
    )
  }
}
