import * as React from 'react'
import { ILogChange } from 'contracts/general/interfaces/ILog'
import { observer } from 'mobx-react'
import { DetailDelete } from './DetailDelete'
import { DetailFromTo } from './DetailFromTo'
import { DetailInitial } from './DetailInitial'

interface Props {
  view: 'create' | 'update' | 'delete'
  changes: ILogChange[]
}

function stopPropagation(e) {
  e.stopPropagation()
}

@observer
export class LogDetails extends React.Component<Props, {}> {
  render() {
    return (
      <table className='text-sm mt-4 border-collapse ml-[60px]' onClick={stopPropagation}>
        <tbody>
          {this.props.view === 'create' &&
            this.props.changes.map((detail, key) => (
              <DetailInitial key={key} detail={detail} />
            ))}
          {this.props.view === 'update' &&
            this.props.changes.map((detail, key) => (
              <DetailFromTo key={key} detail={detail} />
            ))}
          {this.props.view === 'delete' &&
            this.props.changes.map((detail, key) => (
              <DetailDelete key={key} detail={detail} />
            ))}
        </tbody>
      </table>
    )
  }
}
